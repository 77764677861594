import { Form, Link, useActionData, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Plus, Terminal } from 'lucide-react';

import { Avatar, SttProvider, User } from '../types';
import { backendUrl } from '..';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import {getPicture} from "@/utils/getPicture";
import React from "react";
import {PICTURE_SIZE} from "@/constants";

type AvatarsLoaderData = {
  avatars: Avatar[];
  sttProviders: SttProvider[];
};

const Avatars = () => {
  const me = useRouteLoaderData('me') as User;
  const data = useLoaderData() as AvatarsLoaderData;
  const { avatars, sttProviders } = data;
  const recommendedSttProviderId = sttProviders[0].id;
  const actionData = useActionData() as { error?: string }; // Get the error from the action

  const cutDescription = (name: string, desc: string) => {
    let res = desc;

    const removeName = desc.replace(name + ' ', '');
    res = removeName.charAt(0).toUpperCase() + removeName.slice(1);

    return res;
  };

  return (
    <>
      {actionData?.error && (
        <Alert variant='destructive'>
          <Terminal className='h-4 w-4' />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{actionData.error}</AlertDescription>
        </Alert>
      )}
      <div className='ml-auto flex items-center justify-between w-full pe-4'>
        <div>
          <h1 className={'font-bold text-2xl'}>Avatars</h1>
          <h3>Overview</h3>
        </div>

        <Button size='sm' className='h-8 gap-2 rounded-2xl pe-6 font-semibold'>
          <Plus className='h-3.5 w-3.5' />
          <Link to='/avatars/new'>Avatar</Link>
        </Button>
      </div>
      <div className='grid gap-1 md:gap-6'>
        {avatars.map((avatar) => (
          <Card x-chunk='dashboard-06-chunk-0' key={avatar.id} className='rounded-none border-0'>
            <Collapsible>
              <CardHeader className='space-y-0 items-center flex flex-row bg-[#d3dee4] gap-4 p-0 md:p-0 '>
                <div className='grid gap-0.5'>
                  <img
                    src={getPicture(avatar, 'avatars', false, PICTURE_SIZE.semiMedium)}
                    srcSet={getPicture(avatar, 'avatars', true, PICTURE_SIZE.semiMedium)}
                    alt=''
                  />
                </div>

                <div className='grid gap-0.5'>
                  <CardTitle className='group flex items-center gap-2 text-lg'>{avatar.name}</CardTitle>
                  <CardDescription className={'text-muted-0'}>{cutDescription(avatar.name, avatar.shortDesc)}</CardDescription>
                  <div className='flex gap-1 me-4 font-bold'>
                    <Link to={`/avatars/${avatar.id}`} className='underline text-sm'>
                      Character
                    </Link>
                  </div>
                </div>
                <div className='ml-auto flex items-center gap-1 me-4'>
                  <CollapsibleTrigger>
                    <Button size='sm' className={'rounded-2xl  font-semibold px-4'}>
                      Choose
                    </Button>
                  </CollapsibleTrigger>
                </div>
              </CardHeader>

              <CollapsibleContent>
                <CardContent className={'p-0 md:p-0'}>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead className='text-right'></TableHead>
                      </TableRow>
                    </TableHeader>

                    <TableBody>
                      {avatar.scenarios.map((scenario) => (
                        <TableRow key={scenario.id}>
                          <TableCell>{scenario.name}</TableCell>
                          <TableCell>{scenario.shortDesc}</TableCell>

                          <TableCell className='text-right'>
                            {scenario.chats.length ? (
                              <Link to={`/chats/${scenario.chats[0].id}`}>
                                <Button type='submit' size='sm' className={'rounded-2xl h-[31px] font-semibold'}>
                                  Continue chat
                                </Button>
                              </Link>
                            ) : (
                              <Form method='post' id='chat-form'>
                                <input hidden name='scenarioId' id='scenarioId' value={scenario.id} readOnly />
                                <input hidden name='sttProviderId' id='sttProviderId' value={recommendedSttProviderId} readOnly />
                                <Button type='submit' size='sm' className={'rounded-2xl h-[31px] font-semibold'}>
                                  Start chat
                                </Button>
                              </Form>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </CollapsibleContent>
            </Collapsible>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Avatars;
