import { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';

import { backendUrl } from '..';
import { IScenarioEdit } from '../types';
import { setLastPath } from '@/utils/common';

import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { CardHeader, CardTitle, CardContent, CardFooter, Card } from '@/components/ui/card';
import { Slider } from '@/components/ui/slider';
import SelectOptGroup from '@/components/SelectGroup';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.scenarioId) return;

  try {
    await axios.patch(`${backendUrl}/scenarios/${params.scenarioId}`, updates, config);
    return redirect(`/scenarios/${params.scenarioId}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioEdit() {
  const data = useLoaderData() as IScenarioEdit;
  const { scenario, aiProviders } = data;
  const {
    name,
    shortDesc,
    greeting,
    published,
    description,
    avatarId,
    chatModel,
    embeddingModel,
    maxTokens: initialMaxTokens, // added initial maxTokens
    temperature: initialTemperature,
    topP: initialTopP,
    frequencyPenalty: initialFrequencyPenalty,
    presencePenalty: initialPresencePenalty,
  } = scenario;

  const [checked, setChecked] = useState(published);
  const [temperature, setTemperature] = useState(initialTemperature);
  const [topP, setTopP] = useState(initialTopP);
  const [frequencyPenalty, setFrequencyPenalty] = useState(initialFrequencyPenalty);
  const [presencePenalty, setPresencePenalty] = useState(initialPresencePenalty);

  const getOptions = (forChatModels: boolean) => {
    let res: any[] = [];

    aiProviders.forEach((aiProvider) => {
      let newOptionGroup: any = { groupName: aiProvider.name, options: [] };

      const modelsArr: any[] = forChatModels ? aiProvider.chatModels : aiProvider.embeddingModels;

      if (modelsArr && modelsArr.length === 0) {
        return;
      }

      modelsArr.forEach((model) => {
        newOptionGroup.options.push({
          label: model.name,
          value: model.id,
        });
      });

      res.push(newOptionGroup);
    });

    return res;
  };

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit Scenario</CardTitle>
      </CardHeader>
      <Form method='post' id='scenario-form' encType='multipart/form-data'>
        <input type='text' name='avatarId' value={avatarId} readOnly hidden />
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Alice' defaultValue={name} required />
              <p className='text-xs text-gray-500'>Enter the scenario's name, such as 'Alice'.</p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input
                id='shortDesc'
                name='shortDesc'
                type='text'
                className='w-full'
                placeholder='Alice the helpful assistant'
                defaultValue={shortDesc}
                required
              />
              <p className='text-xs text-gray-500'>Provide a brief description of the scenario, like 'Alice the helpful assistant'.</p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='greeting'>Greeting</Label>
              <Textarea id='greeting' name='greeting' rows={2} className='w-full' placeholder='Alice' defaultValue={greeting} required />
              <p className='text-xs text-gray-500'>
                What should the scenario say when it starts? Example: 'Hello, I'm Alice, your assistant.'
              </p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='description'>Description</Label>
              <Textarea
                required
                defaultValue={description}
                id='description'
                name='description'
                rows={20}
                placeholder='Description in W++ format'
              />
              <p className='text-xs text-gray-500'>A detailed description of the scenario in W++ format.</p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='published'>Published</Label>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} />
              <p className='text-xs text-gray-500'>
                Published makes a scenario visible to everyone. Only works if the avatar is published too.
              </p>
            </div>

            <div className='grid gap-3'>
              <SelectOptGroup label={'Chat Model'} options={getOptions(true)} name={'chatModelId'} defaultValue={chatModel.id} />
              <p className='text-xs text-gray-500'>Select the AI chat model for this scenario. Example: GPT-3.5.</p>
            </div>

            <div className='grid gap-3'>
              <SelectOptGroup
                label={'Embedding Model'}
                options={getOptions(false)}
                name={'embeddingModelId'}
                defaultValue={embeddingModel.id}
              />
              <p className='text-xs text-gray-500'>Choose the embedding model used for similarity search. Example: ADA embedding.</p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='temperature'>Temperature</Label>
              <Slider
                id='temperature'
                name='temperature'
                defaultValue={[temperature]}
                min={0}
                max={1}
                step={0.1}
                onValueChange={(value: any) => setTemperature(value[0])}
              />
              <span className='text-xs text-gray-600'>Current Value: {temperature}</span>
              <p className='text-xs text-gray-500'>
                Controls the randomness of the model's output. A value between 0 and 1. Example: 0.7 for creative responses.
              </p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='topP'>TopP</Label>
              <Slider
                id='topP'
                name='topP'
                defaultValue={[topP]}
                min={0}
                max={1}
                step={0.1}
                onValueChange={(value: any) => setTopP(value[0])}
              />
              <span className='text-xs text-gray-600'>Current Value: {topP}</span>
              <p className='text-xs text-gray-500'>
                Controls the diversity of generated content by selecting from the top probability mass. Example: 0.9.
              </p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='frequencyPenalty'>Frequency Penalty</Label>
              <Slider
                id='frequencyPenalty'
                name='frequencyPenalty'
                defaultValue={[frequencyPenalty]}
                min={0}
                max={1}
                step={0.1}
                onValueChange={(value: any) => setFrequencyPenalty(value[0])}
              />
              <span className='text-xs text-gray-600'>Current Value: {frequencyPenalty}</span>
              <p className='text-xs text-gray-500'>
                Applies a penalty for reusing similar phrases. Higher values reduce repetition. Example: 0.1.
              </p>
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='presencePenalty'>Presence Penalty</Label>
              <Slider
                id='presencePenalty'
                name='presencePenalty'
                defaultValue={[presencePenalty]}
                min={0}
                max={1}
                step={0.1}
                onValueChange={(value: any) => setPresencePenalty(value[0])}
              />
              <span className='text-xs text-gray-600'>Current Value: {presencePenalty}</span>
              <p className='text-xs text-gray-500'>
                Applies a penalty for introducing new concepts. Higher values encourage creativity. Example: 0.2.
              </p>
            </div>
          </div>
        </CardContent>

        <CardFooter>
          <Button type='submit'>Save Changes</Button>
          <Link to={`/scenarios/${scenario.id}`}>
            <Button type='button' variant='secondary' className='ml-4'>
              Cancel
            </Button>
          </Link>
        </CardFooter>
      </Form>
    </Card>
  );
}
