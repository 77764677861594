import React, { useEffect, useState } from 'react';
import { PICTURE_SIZE } from '@/constants';
import { getPicture } from '@/utils/getPicture';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';

type Option = {
  id: string;
  picture: string;
  label: string;
  value: string;
  imgUrl: string;
};

type SelectProps = {
  options: Option[];
  className?: string;
  placeholder?: string;
  onChange?: (selectedValue: string) => void;
  label?: string;
  id?: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
};

const SelectWithImage: React.FC<SelectProps> = ({ label, id, name, placeholder, className, options, defaultValue, disabled, onChange }) => {
  const [defaultVal, setDefaultVal] = useState<string | undefined>(defaultValue ?? '');

  useEffect(() => {
    if (defaultValue) {
      setDefaultVal(defaultValue); // Set the default value if provided
    }
  }, [defaultValue]);

  return (
    <div className={className ? className : ''}>
      {label && (
        <label htmlFor={id} className='block text-sm font-medium leading-0 text-gray-900 mb-0.5'>
          {label}
        </label>
      )}

      <Select onValueChange={onChange} disabled={disabled} name={name} defaultValue={defaultVal}>
        <SelectTrigger
          className={cn(`[&_span:first-child]:flex [&_span:first-child]:items-center 
                 [&_span:first-child]:gap-1 pl-2 pe-0.5 [&_svg]:opacity-100 font-normal`)}
        >
          <SelectValue placeholder={placeholder ?? 'Select an option'} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={'null'} disabled={true}>
            Select an option
          </SelectItem>
          {options.map((option, key) => (
            <SelectItem
              value={option.value}
              className={cn(`px-1 py-1 [&_span:first-child]:hidden [&_span:last-child]:flex 
                        [&_span:last-child]:items-center [&_span:last-child]:gap-1`)}
              key={key}
            >
              <img
                className='w-6 h-6'
                src={getPicture(option, option.imgUrl, false, PICTURE_SIZE.small)}
                srcSet={getPicture(option, option.imgUrl, true, PICTURE_SIZE.small)}
                alt={option.label}
              />
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectWithImage;
