import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { setLastPath } from '@/utils/common';
import { TtsProvider } from '@/types';
import SelectOptGroup from '@/components/SelectGroup';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');
  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };
  const formData = await request.formData();
  const body = Object.fromEntries(formData);
  try {
    await axios.post(`${backendUrl}/avatars/`, body, config);
    return redirect(`/avatars`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function AvatarNew() {
  const ttsProviders = useLoaderData() as TtsProvider[];

  const [checked, setChecked] = useState(false);

  const getOptions = () => {
    let res: any[] = [];

    ttsProviders.forEach((ttsProvider) => {
      let newOptionGroup: any = { groupName: ttsProvider.name, options: [] };

      const voices = ttsProvider.ttsVoices;

      if (voices && voices.length === 0) {
        return;
      }

      voices.forEach((model) => {
        newOptionGroup.options.push({
          label: model.name,
          value: model.id,
        });
      });

      res.push(newOptionGroup);
    });

    return res;
  };

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>New Avatar</CardTitle>
      </CardHeader>
      <Form method='post' id='avatar-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Alice' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input id='shortDesc' name='shortDesc' type='text' className='w-full' placeholder='Alice the helpful assistant' required />
            </div>

            <div className='grid gap-3'>
              <SelectOptGroup label={'Voice'} options={getOptions()} name={'ttsVoiceId'} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Character</Label>
              <Textarea required id='character' name='character' rows={20} placeholder='Character description in w++ format' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Picture</Label>
              <Input id='file-button' name='picture' type='file' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>published</Label>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/avatars`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
