import { Link } from 'react-router-dom';
import moment from 'moment';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { getFormattedTime } from '@/utils/formatters';
import { Chat } from '../types';

const Chats = ({ chats }: { chats: Chat[] }) => {
  return (
    <Card className='overflow-x-auto rounded-none'>
      <CardHeader className='bg-muted/50 '>
        <CardTitle>Chats</CardTitle>
        <CardDescription>Showing all your Chats.</CardDescription>
      </CardHeader>
      <CardContent className={'p-0 md:p-0'}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Avatar</TableHead>
              <TableHead>Scenario</TableHead>
              <TableHead className='hidden sm:table-cell'>Messages</TableHead>
              <TableHead>Last Message Time</TableHead>
              <TableHead className='text-right'></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {chats.map((chat) => (
              <TableRow key={chat.id}>
                <TableCell>
                  <Button size='sm' variant='link' className={'p-0'}>
                    <Link to={`/avatars/${chat.scenario.avatar.id}`}>{chat.scenario.avatar.name || 'Avatar'}</Link>
                  </Button>
                </TableCell>
                <TableCell>
                  <Button size='sm' variant='link' className={'p-0'}>
                    <Link to={`/scenarios/${chat.scenario.id}`}>{chat.scenario.name || 'Scenario'}</Link>
                  </Button>
                </TableCell>

                <TableCell className='hidden sm:table-cell'>{chat._count.messages}</TableCell>
                <TableCell>
                  <span className='text-gray-500 text-xs'>{getFormattedTime(moment(chat.updatedAt))}</span>
                </TableCell>
                <TableCell className='text-right gap-4'>
                  <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                    <Link to={`/chats/${chat.id}`}>Messages</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Chats;
