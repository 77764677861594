import { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const PropertyError: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let returnTo = pathname.split('/')[1] ? `/${pathname.split('/')[1]}` : pathname.split('/').slice(0, -1).join('/');
  let returnToTitle = pathname.split('/')[1] ?? pathname.split('/').slice(0, -1).join(' ');

  useEffect(() => {
    if (returnToTitle === 'messages') {
      returnTo = '/chats';
      returnToTitle = 'Chats';
    }
    if (returnToTitle === 'users') {
      navigate('/404');
    }
  }, []);

  return (
    <div className='flex min-h-[100dvh] flex-col items-center justify-center bg-gray-50 px-4 py-12 dark:bg-gray-950'>
      <div className='container mx-auto flex max-w-3xl flex-col-reverse items-center gap-8 md:flex-row md:gap-12'>
        <div className='flex flex-1 flex-col items-center justify-center space-y-4 text-center md:items-start md:text-left'>
          <h1 className='text-4xl font-bold tracking-tighter sm:text-5xl'>Oops! Something went wrong.</h1>
          <p className='max-w-[450px] text-gray-500 dark:text-gray-400'>
            The page you're looking for doesn't exist or has been moved. Let's get you back on track.
          </p>
          <Link
            to={returnTo}
            className='inline-flex gap-1 h-10 items-center justify-center rounded-md bg-gray-900 px-6 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300'
          >
            Go to <span className='capitalize'> {returnToTitle}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PropertyError;
