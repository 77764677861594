import React, { Fragment } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { AiProvider, User } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { backendUrl } from '@/index';
import { Plus } from 'lucide-react';
import {PICTURE_SIZE, USER_TYPE} from '@/constants';
import {getPicture} from "@/utils/getPicture";

const AiProviderList: React.FC = () => {
  const me = useRouteLoaderData('me') as User;
  const list = useLoaderData() as AiProvider[];

  return (
    <>
      {me.role === USER_TYPE.admin && (
        <div className='ml-auto flex items-center justify-between w-full pe-4'>
          <div>
            <h1 className={'font-bold text-2xl'}>AI-Providers</h1>
            <h3>Overview</h3>
          </div>

          <Button size='sm' className='h-8 gap-2 pe-6 rounded-2xl font-semibold'>
            <Plus className='h-3.5 w-3.5' />
            <Link to='/ai-providers/new'>AI-Provider</Link>
          </Button>
        </div>
      )}
      {list.map((provider) => (
        <Card key={provider.id} x-chunk='dashboard-06-chunk-0' className='rounded-none'>
          <CardHeader className='flex space-y-0 flex-row bg-[#d3dee4] items-center gap-3 p-0 md:p-0'>
            <div className='grid gap-0.5'>
              <img
                src={getPicture(provider, 'ai-providers', false, PICTURE_SIZE.semiMedium)}
                srcSet={getPicture(provider, 'ai-providers', true, PICTURE_SIZE.semiMedium)}
                alt={''}
              />
            </div>
            <div className='grid gap-0.5'>
              <CardTitle className='group flex items-center gap-2 text-lg'>{provider.name}</CardTitle>
            </div>
            <div className='ml-auto flex items-center gap-1 me-4'>
              <Button size='sm' className={'rounded-2xl p-4 font-semibold'}>
                <Link to={`/ai-providers/${provider.id}`}>View</Link>
              </Button>
            </div>
          </CardHeader>

          {provider.chatModels && provider.chatModels.length > 0 && (
            <Fragment>
              <CardContent className={'p-0 md:p-0'}>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead>Dollar Per Input Token</TableHead>
                      <TableHead>Dollar Per Output Token</TableHead>
                      <TableHead>Max Tokens</TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {provider.chatModels?.map((model) => (
                      <TableRow key={model.id}>
                        <TableCell className={'p-1 sm:p-2'}>{model.name}</TableCell>
                        <TableCell className={'p-1 sm:p-2'}>${scientificNumConvert(model.dollarPerInputToken)}</TableCell>
                        <TableCell className={'p-1 sm:p-2'}>${scientificNumConvert(model.dollarPerOutputToken)}</TableCell>
                        <TableCell className={'p-1 sm:p-2'}>{model.maxTokens}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Fragment>
          )}

          {provider.embeddingModels && provider.embeddingModels.length > 0 && (
            <Fragment>
              <CardContent className={'p-0 md:p-0'}>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead>Dollar Per Input Token</TableHead>
                      <TableHead>Dollar Per Output Token</TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {provider.embeddingModels?.map((model) => (
                      <TableRow key={model.id}>
                        <TableCell>{model.name}</TableCell>
                        <TableCell>${scientificNumConvert(model.dollarPerInputToken)}</TableCell>
                        <TableCell>${scientificNumConvert(model.dollarPerOutputToken)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Fragment>
          )}
        </Card>
      ))}
    </>
  );
};

export default AiProviderList;
