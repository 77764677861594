import { ActionFunctionArgs, redirect } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';

export const USER_TYPE = {
  admin: 'ADMIN',
  user: 'USER',
};

export const LAST_PATH = 'lastPath';

export const PICTURE_SIZE = {
  smallest: 'smallest',
  small: 'small',
  semiMedium: 'semiMedium',
  medium: 'medium',
  default: 'default'
}