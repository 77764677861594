import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PlayIcon, StopIcon } from '@radix-ui/react-icons';

const AudioPlayer: React.FC<{ audioSrc: string }> = ({ audioSrc }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const speakingAnimation = {
    scale: isPlaying ? [1, 1.1, 1] : 1,
    transition: {
      duration: 0.6,
      repeat: isPlaying ? Infinity : 0,
      ease: 'easeInOut',
    },
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const handleEnded = () => {
        setIsPlaying(false);
      };

      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [audioRef]);

  return (
    <div>
      <motion.button
        className={`w-10 h-10 rounded-full flex items-center justify-center 
          bg-white dark:bg-black border border-gray-300 dark:border-gray-600 
          shadow-md transition-transform transform 
          hover:scale-105 focus:outline-none focus:ring-2 
          focus:ring-gray-300 dark:focus:ring-gray-700`}
        onClick={handlePlayPause}
        animate={speakingAnimation}
      >
        <span className='text-black dark:text-white'>
          {isPlaying ? <StopIcon className='h-5 w-5' /> : <PlayIcon className='h-5 w-5' />}
        </span>
      </motion.button>
      <audio ref={audioRef} src={audioSrc} />
    </div>
  );
};

export default AudioPlayer;
