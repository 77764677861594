import * as React from 'react';
import { useEffect, useState } from 'react';

import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select';
import { SelectGroupOption } from '@/types';

interface SelectInputProps {
  label?: string;
  id?: string;
  name: string;
  placeholder?: string;
  options: SelectGroupOption[];
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectOptGroup: React.FC<SelectInputProps> = ({ label, id, name, placeholder, options, defaultValue, onChange }) => {
  const [defaultVal, setDefaultVal] = useState<string | undefined>(defaultValue ?? '');

  useEffect(() => {
    if (defaultValue) {
      setDefaultVal(defaultValue); // Set the default value if provided
    }
  }, [defaultValue]);

  return (
    <Select defaultValue={defaultVal} name={name}>
      {label && (
        <label htmlFor={id} className='block text-sm font-medium leading-6 text-gray-900 dark:text-white'>
          {label}
        </label>
      )}
      <SelectTrigger className='w-full'>
        <SelectValue placeholder={placeholder ? placeholder : 'Select value'} />
      </SelectTrigger>
      <SelectContent>
        {options.map((option, i) => {
          return (
            <SelectGroup key={i}>
              <SelectLabel>{option.groupName}</SelectLabel>
              {option.options.map((groupOption, optKey) => (
                <SelectItem key={optKey} value={groupOption.value}>
                  {groupOption.label}
                </SelectItem>
              ))}
            </SelectGroup>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default SelectOptGroup;
