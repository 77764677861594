import React, { useEffect, useRef, useState } from 'react';
import { ActionFunction, json, redirect, useFetcher, useRouteLoaderData, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import mqtt from 'mqtt';
import * as localForage from 'localforage';

import { backendUrl } from '..';
import MessageTextNew from './MessageTextNew';
import MessageAudioNew from './MessageAudioNew';
import { AudioEvent, Chat, TtsJob, User } from '@/types';

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
       Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    // Make the API request to send the message
    await axios.post(`${backendUrl}/messages`, body, config);

    // Redirect back to the messages page after submission
    return redirect(`/chats/${params.chatId}`);
  } catch (error: any) {
    // In case of an error, return a JSON response with error details
    return json({ error: error.response?.data?.message || 'Something went wrong' }, { status: 400 });
  }
};

const MessageNew: React.FC = () => {
  const me = useRouteLoaderData('me') as User;

  const chat = useRouteLoaderData('chat') as Chat;

  const [audioURL, setAudioURL] = React.useState<string | null>(null);

  const mqttClientRef = React.useRef<mqtt.MqttClient | null>(null);

  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}/audioEvents`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const audioEvent: AudioEvent = JSON.parse(message.toString());
          if (audioEvent.action === 'play') {
            setAudioURL(`${backendUrl}/messages/${audioEvent.messageId}/audio`);
          }
        }
      };

      client.on('message', handleMessage);

      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end();
        mqttClientRef.current = null;
      };
    }
  }, [me.id]);

  const handleNewRecording = () => {
    console.log('handleNewRecording');
    setAudioURL(null);
    console.log('audioURL', audioURL);
  };

  return (
    <div className='flex justify-between items-center gap-5 w-full relative'>
      {audioURL && <audio key={audioURL} src={audioURL} autoPlay />}

      <div className='flex justify-between w-full items-center gap-5'>
        <div className={'w-full'}>
          <MessageTextNew chatId={chat.id} />
        </div>
        <MessageAudioNew chatId={chat.id} onRecordingStart={handleNewRecording} />
      </div>
    </div>
  );
};

export default MessageNew;
