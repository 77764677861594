import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { AiProvider } from '@/types';
import { setLastPath } from '@/utils/common';
import { getPicture } from "@/utils/getPicture";
import { PICTURE_SIZE } from "@/constants";

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';


export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  if (!params.aiProviderId) return;

  try {
    await axios.patch(`${backendUrl}/ai-providers/${params.aiProviderId}`, body, config);
    return redirect(`/ai-providers/${params.aiProviderId}`);
  } catch (error) {
    console.log('error', error);
  }
};

const AiProviderEdit = () => {
  const provider = useLoaderData() as AiProvider;

  const [name, setName] = useState(provider.name);
  const [apiKey, setApiKey] = useState(provider.apiKey);
  const [basePath, setBasePath] = useState(provider.basePath);
  const [image, setImage] = useState<FileList | any>('');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit AI-Provider</CardTitle>
      </CardHeader>
      <Form method='post' id='ai-provider-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='apiKey'>API Key</Label>
              <Input
                id='apiKey'
                name='apiKey'
                placeholder={'API Key'}
                required
                defaultValue={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='basePath'>Base Path</Label>
              <Input
                id='basePath'
                name='basePath'
                className='w-full'
                placeholder='Base path'
                required
                type={'text'}
                defaultValue={basePath}
                onChange={(e) => setBasePath(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='file-button'>Picture</Label>
              <div>
                {image && typeof image !== 'string' ? (
                  <img alt='ai-provider-picture' className='h-10 w-10' src={URL.createObjectURL(image[0])} />
                ) : !provider.picture ? (
                  <img alt='ai-provider-picture' className='h-10 w-10' src={`https://placehold.co/200x200?text=No+Picture`} />
                ) : (
                  ''
                )}
                {provider.picture && !image && (
                  <img
                    src={getPicture(provider, 'ai-providers', false, PICTURE_SIZE.small)}
                    srcSet={getPicture(provider, 'ai-providers', true, PICTURE_SIZE.small)}
                    alt={''}
                  />
                )}
              </div>
            </div>

            <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/ai-providers/${provider.id}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default AiProviderEdit;
