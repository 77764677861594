import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Doll, User, MetricsEvent, Scenario } from '../types';
import mqtt from 'mqtt';

import DollDelete from './DollDelete';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import React, { useEffect, useRef, useState } from 'react';
import { getPicture } from '@/utils/getPicture';
import { Progress } from '@/components/ui/progress';
import { Label } from '@/components/ui/label';

import excellent from '../assets/wifiIcons/excellent.svg';
import good from '../assets/wifiIcons/good.svg';
import fair from '../assets/wifiIcons/fair.svg';
import weak from '../assets/wifiIcons/weak.svg';

type RamData = {
  initialSRAM: number;
  initialPSRAM: number;
  freeSRAM: number;
  freePSRAM: number;
  freeSRAMPercent: number;
  freePSRAMPercent: number;
  usedSRAM: number;
  usedPSRAM: number;
  usedSRAMPercent: number;
  usedPSRAMPercent: number;
};

type IGetWlanLvl = {
  icon: string;
  text: string;
  textColor: string;
  range: string;
};

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

export default function DollView() {
  const doll = useLoaderData() as Doll;
  const me = useRouteLoaderData('me') as User;

  const [metricsEvent, setMetricsEvent] = useState<MetricsEvent | null>(null);

  const [wlanData, setWlanData] = useState<IGetWlanLvl | null>(null);
  const [ramData, setRamData] = useState<RamData | null>(null);

  const mqttClientRef = useRef<mqtt.MqttClient | null>(null);
  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;
      const userTopic = `dolls/${doll.id}/metrics`;
      client.subscribe(userTopic);
      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const metricsEvent: MetricsEvent = JSON.parse(message.toString());
          setMetricsEvent(metricsEvent);
        }
      };
      client.on('message', handleMessage);
      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, []); // Only re-run the effect if the user ID changes

  useEffect(() => {
    if (metricsEvent) {
      const wlanLvl = getWlanLvl(metricsEvent);
      setWlanData(wlanLvl);

      const ramData = getRamData(metricsEvent);
      setRamData(ramData);
    }
  }, [metricsEvent]);

  const getRamData = (metricsEvent: MetricsEvent): RamData => {
    return {
      initialSRAM: Math.round(metricsEvent.initialSRAM / 1024),
      initialPSRAM: Math.round(metricsEvent.initialPSRAM / 1024),
      freeSRAM: Math.round(metricsEvent.freeSRAM / 1024),
      freePSRAM: Math.round(metricsEvent.freePSRAM / 1024),
      freeSRAMPercent: (metricsEvent.freeSRAM / metricsEvent.initialSRAM) * 100,
      freePSRAMPercent: (metricsEvent.freePSRAM / metricsEvent.initialPSRAM) * 100,
      usedSRAM: Math.round((metricsEvent.initialSRAM - metricsEvent.freeSRAM) / 1024),
      usedPSRAM: Math.round((metricsEvent.initialPSRAM - metricsEvent.freePSRAM) / 1024),
      usedSRAMPercent: ((metricsEvent.initialSRAM - metricsEvent.freeSRAM) / metricsEvent.initialSRAM) * 100,
      usedPSRAMPercent: ((metricsEvent.initialPSRAM - metricsEvent.freePSRAM) / metricsEvent.initialPSRAM) * 100,
    };
  };

  const getImageByName = (name: string) => {
    if (!name) return;
    const icons = [excellent, good, fair, weak];
    return icons.filter((icon) => icon.includes(name))[0];
  };

  const getWlanLvl = (metricsEvent: MetricsEvent): IGetWlanLvl => {
    let res = {
      icon: getImageByName('excellent'),
      text: 'Excellent',
      textColor: '#5fcf95',
      range: '> -50 dBm',
    };

    if (metricsEvent.wifiRSSI <= -50 && metricsEvent.wifiRSSI >= -60) {
      res = {
        icon: getImageByName('good'),
        text: 'Good',
        textColor: '#3d8bf3',
        range: '-50 to -60 dBm',
      };
    } else if (metricsEvent.wifiRSSI <= -60 && metricsEvent.wifiRSSI >= -70) {
      res = {
        icon: getImageByName('fair'),
        text: 'Fair',
        textColor: '#f4e56b',
        range: '-60 to -70 dBm',
      };
    } else if (metricsEvent.wifiRSSI <= -70) {
      res = {
        icon: getImageByName('weak'),
        text: 'Weak',
        textColor: '#e9615c',
        range: '< -70 dBm',
      };
    }

    return res as IGetWlanLvl;
  };

  return (
    <div className={'flex text-sm w-full flex-row items-start'}>
      <div className='grid w-full gap-2 md:gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))' }}>
        <div className={'flex flex-col gap-2 md:gap-4'}>
          <Card className={'h-fit text-card-inherit rounded-none'}>
            <CardHeader className={'md:py-2 md:px-4 px-2 py-2 border-b-[1px]'}>
              <h2 className={'font-semibold text-base'}>Wlan</h2>
            </CardHeader>

            <CardContent className={'md:p-4 p-4'}>
              <div className='grid gap-1 mb-5'>
                <img src={wlanData?.icon} alt={''} />
                <h3 className={`temperature text-center text-xl text-[${wlanData?.textColor}]`}>{wlanData?.text}</h3>
                <h4 className={`temperature text-center text-base text-[#cbcfd7]`}>{wlanData?.range}</h4>
              </div>
            </CardContent>
          </Card>

          <Card className={'h-fit rounded-none'}>
            <CardHeader className={'md:py-2 md:px-4 px-2 py-2 border-b-[1px]'}>
              <h2 className={'font-semibold text-base'}>Status</h2>
            </CardHeader>

            <CardContent className={'md:p-4 p-4'}>
              <div className='flex items-center gap-2 mb-3'>
                <span className={`w-6 h-6 rounded-xl bg-[${metricsEvent?.recording ? '#f54242' : '#521912'}]`} />
                Recording
              </div>

              <div className='flex items-center gap-2 mb-3'>
                <span className={`w-6 h-6 rounded-xl bg-[${metricsEvent?.t1 ? '#f54242' : '#521912'}]`} />
                Button T1
              </div>

              <div className='flex items-center gap-2 mb-3'>
                <span className={`w-6 h-6 rounded-xl bg-[${metricsEvent?.t2 ? '#f54242' : '#521912'}]`} />
                Button T2
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className={'h-fit rounded-none'}>
          <CardHeader className={'md:py-2 md:px-4 px-2 py-2 border-b-[1px]'}>
            <h2 className={'font-semibold text-base'}>Ram</h2>
          </CardHeader>

          <CardContent className={'md:p-4 p-4'}>
            <div className='grid gap-1 mb-5'>
              <Label htmlFor='temperature'>SRAM</Label>
              <Progress value={ramData?.usedSRAMPercent} />
              <span className='text-xs text-gray-600'>
                {ramData?.usedSRAM}kb used of {ramData?.initialSRAM}kb
              </span>
            </div>

            <div className='grid gap-1'>
              <Label htmlFor='temperature'>PSRAM</Label>
              <Progress value={ramData?.usedPSRAMPercent} />
              <span className='text-xs text-gray-600'>
                {ramData?.usedPSRAM}kb used of {ramData?.initialPSRAM}kb
              </span>
            </div>
          </CardContent>
        </Card>

        <Card className={'h-fit rounded-none'}>
          <CardHeader className={'md:py-2 md:px-4 px-2 py-2 border-b-[1px]'}>
            <h2 className={'font-semibold text-base'}>Doll</h2>
          </CardHeader>

          <CardContent className={'md:p-4 p-4'}>
            <img className={'mb-3'} src={getPicture(doll, 'dolls', false)} srcSet={getPicture(doll, 'dolls', true)} alt='' />

            <div className={'flex flex-wrap mb-3'}>
              <span className={'w-24'}>macAddress:</span> {doll.macAddress}
            </div>
            <div className={'flex flex-wrap '}>
              <span className={'w-24'}>Name:</span> {doll.name}
            </div>
          </CardContent>

          {me.id === doll.userId && (
            <CardFooter className={'md:py-2 md:px-4 px-2 py-2'}>
              <Button className='gap-1' size={'sm'}>
                <Link to={`/dolls/${doll.id}/edit`}>Edit</Link>
              </Button>

              <Separator className={'h-7 mx-1'} orientation={'vertical'} />

              <DollDelete />
            </CardFooter>
          )}
        </Card>
      </div>
    </div>
  );
}
