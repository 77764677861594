import React from 'react';
import { ActionFunction, Form, redirect } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  if (!params.modelId) return;

  try {
    await axios.delete(`${backendUrl}/chat-models/${params.modelId}`, config);
    return redirect(`/ai-providers`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ChatModelDelete() {
  return (
    <Form method='post' action='destroy'>
      <Button type='submit' variant='destructive'>
        Delete
      </Button>
    </Form>
  );
}
