import React from "react";
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Avatar, User } from '../types';
import { getPicture } from "@/utils/getPicture";

import AvatarDelete from './AvatarDelete';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { PlusCircle } from 'lucide-react';
import { Separator } from '@radix-ui/react-dropdown-menu';

export default function AvatarView() {
  const avatar = useLoaderData() as Avatar;
  const me = useRouteLoaderData('me') as User;

  const checkHasAvatarChats = (): boolean => {
    let res = false;

    if (avatar.scenarios) {
      res = avatar.scenarios.some(scenario => scenario.chats.length > 0);
    }

    return res;
  }

  return (
    <main className='grid flex-1 items-start gap-4 sm:px-0 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Character Card</CardTitle>
          </CardHeader>
          <CardContent>
            <div className='whitespace-pre-line'>{avatar.character}</div>
          </CardContent>
        </Card>

        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Scenarios</CardTitle>
          </CardHeader>
          <CardContent className={'p-0 md:p-0'}>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[100px]'>Name</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead className='text-right'></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {avatar.scenarios.map((scenario) => (
                  <TableRow key={scenario.id}>
                    <TableCell className='font-medium'>{scenario.name}</TableCell>
                    <TableCell>{scenario.shortDesc}</TableCell>

                    <TableCell className='text-right'>
                      <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                        <Link to={`/scenarios/${scenario.id}`}>View</Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          {me.id === avatar.userId && (
            <CardFooter className='justify-center border-t p-1'>
              <Button size='sm' variant='ghost' className='gap-1'>
                <PlusCircle className='h-3.5 w-3.5' />
                <Link to={`/avatars/${avatar.id}/scenarios/new`}>New Scenario</Link>
              </Button>
            </CardFooter>
          )}
        </Card>
      </div>

      <Card className='rounded-none'>
        <CardHeader>
          <CardTitle>{avatar.name}</CardTitle>
          <CardDescription>{avatar.shortDesc}</CardDescription>
        </CardHeader>

        <CardContent>
          <img
            className='object-cover'
            src={getPicture(avatar, 'avatars', false)}
            srcSet={getPicture(avatar, 'avatars', true)}
            alt={''}
          />

          <Separator className='my-4' />
          <div className='font-semibold'>Creator</div>
          <span className='text-muted-foreground'>{avatar.userId}</span>
        </CardContent>

        {me.id === avatar.userId && (
          <CardFooter className='flex justify-between'>
            <>
              <Button className='gap-1'>
                <Link to={`/avatars/${avatar.id}/edit`}>Edit</Link>
              </Button>
              <AvatarDelete disabled={checkHasAvatarChats()}/>
            </>
          </CardFooter>
        )}
      </Card>
    </main>
  );
}
