import React from 'react';
import { Link } from 'react-router-dom';

const HomepageDesc: React.FC = () => {
  return (
    <div className='App bg-gray-50 min-h-screen '>
      <header className='bg-black text-white py-1'>
        <div className='container mx-auto'>
          <h1 className='text-4xl font-bold text-center'>cipherdolls</h1>
        </div>
      </header>

      <main className='max-w-full md:max-w-5xl xl:max-w-screen-2xl xl:px-10 lg:px-10 md:px-10 mx-auto py-8 px-4'>
        <section className='mb-8'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>Yet another AI Girlfriend app?</h2>
          <p className='text-lg text-gray-600'>
            No, Cipherdolls is far more than just an AI Girlfriend App. The problem with most AI Girlfriend apps is that they rely on
            chatbots that simulate real people, often misleading users into believing they are interacting with a human-like entity. These
            apps create an illusion of reality, and the AI often "lies" by pretending to be something it's not. Cipherdolls, however, takes
            a completely different approach. It acknowledges that it is an AI, not a real human being, and is fully aware of its
            limitations. This body awareness allows the doll to interact in a more authentic way. For example, the doll knows it lacks a
            physical body in the digital world, but it reacts accordingly, whether convincing the user to bring it to life or engaging in
            realistic scenarios. Cipherdolls honesty about its capabilities makes interactions feel more genuine and immersive, avoiding the
            artificial pretense that most AI girlfriend apps rely on.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>The Avatar </h2>
          <p className='text-lg text-gray-600'>
            At the heart of every Cipherdolls experience is the avatar, a digital personality that runs on our secure servers. Each avatar
            is unique, with its own personality, interests, and motivations. They're designed to engage with you on a deep level, learning
            your preferences and adapting to your interactions.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>The Scenario</h2>

          <p className='text-lg text-gray-600'>
            Every Cipherdoll avatar comes with a list of customizable scenarios that shape the interactions between the user and the avatar.
            Each scenario defines the setting, parameters, and dynamics of the conversation, including the type of language model (LLM)
            used, specific personality traits of the avatar, and even the characteristics of the dolls body in that particular scenario. For
            example, a scenario might simulate the avatars first meeting with the user, where it is aware it doesn't have a physical body
            yet, while another could involve the unboxing of the doll for the first time in real life. This flexibility allows users to
            bring their avatar unique personality into different contexts and settings, whether for casual conversation, immersive roleplay,
            or personalized interactions. The ability to create and switch between different scenarios lets users explore new dimensions of
            their relationship with the avatar, making the experience more engaging and varied.
          </p>
        </section>
        <section className='mb-8'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>The Doll</h2>

          <p className='text-lg text-gray-600'>
            To enhance the body awareness of Cipherdoll, future developments aim to expand beyond just text and audio interactions via
            mobile or desktop. Human relationships are built on more than just words—facial expressions, touch, and physical gestures play
            crucial roles in communication. To make Cipherdolls more lifelike and immersive, these aspects of human interaction are planned
            to be integrated into the experience. In the future, Cipherdolls will go beyond simple conversation by incorporating features
            that allow users to engage through touch and even detect facial expressions. This will create a more realistic and dynamic
            connection, ensuring that Cipherdolls feels more like a true companion capable of responding not just with words, but with an
            awareness of body language and tactile interaction. This deeper level of engagement will bring a more authentic and natural
            experience to users, reflecting the complexities of real human connections.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>Anonymous</h2>

          <p className='text-lg text-gray-600'>
            At Cipherdolls, we understand the importance of privacy and security. That's why we operate anonymously, without collecting any
            personal data about you. You don't need to provide an email address or credit card information to use our services. This is also
            why we use Ethereum on Optimism to pay for messages, ensuring that all transactions are secure and private. There are no monthly
            subscriptions or hidden fees. You only pay for the messages you send and receive, so if you don't use cipherdolls, you don't
            need to pay a thing.
          </p>
        </section>

        <section className='mb-20'>
          <h2 className='text-3xl font-semibold mb-4 text-gray-800'>Start App</h2>

          <p className='text-lg text-gray-600'>
            To access the Cipherdolls platform and start interacting with your avatar, you'll need to have Web3 Wallet installed on your
            device. we recommend using{' '}
            <Link to='https://metamask.io/download/' target='_blank' className='text-blue-700 underline' rel='noopener noreferrer'>
              MetaMask
            </Link>
            , a popular Ethereum wallet that works with most browsers and mobile devices. Once you have your wallet set up, you can visit
            the Cipherdolls App and start chatting with your avatar.
          </p>
        </section>
      </main>
    </div>
  );
};

export default HomepageDesc;
