import moment from 'moment';

export const getFormattedTime = (date: moment.Moment) => {
  const now = moment();
  const minutesDifference = now.diff(date, 'minutes');

  if (minutesDifference < 60) {
    return date.fromNow();
  } else {
    return date.format('MM/DD/YYYY HH:mm:ss');
  }
};
