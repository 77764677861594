import React from 'react';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Separator } from '@radix-ui/react-dropdown-menu';

import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import Dolls from './doll/Dolls';
import Chats from './chat/Chats';
import { Chat, Doll, User } from './types';

const Home: React.FC = () => {
  const me = useRouteLoaderData('me') as User;
  const data = useLoaderData() as { dolls: Doll[]; chats: Chat[] };
  const dolls = data?.dolls as Doll[];
  const chats = data?.chats as Chat[];

  return (
    <div className='text-center flex flex-col gap-4'>
      <Card className='rounded-none'>
        <CardHeader>
          <CardTitle>Quick Start</CardTitle>
        </CardHeader>

        <CardContent>
          {' '}
          To Start a new Chat go to{' '}
          <Link to={`/avatars`} className=' underline'>
            Avatars
          </Link>
          <Separator className='my-4' />
          to Change your name from {me.name} to something else go to{' '}
          <Link to={`/users/${me.id}`} className=' underline'>
            Your Account
          </Link>
        </CardContent>
      </Card>
      <Dolls dolls={dolls} />
      <Chats chats={chats} />
    </div>
  );
};

export default Home;
