import React from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { IEmbeddingModelTable, User } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { USER_TYPE } from '@/constants';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';

const EmbeddingModelTable: React.FC<IEmbeddingModelTable> = ({ list, aiProviderId }) => {
  const me = useRouteLoaderData('me') as User;

  return (
    <Card className='rounded-none'>
      <CardHeader className='flex flex-row items-start bg-muted/50 gap-3'>
        <div className='grid gap-0.5'>
          <CardTitle className='group flex items-center gap-2 text-lg'>Embedding Models</CardTitle>
        </div>
      </CardHeader>
      <CardContent className={'p-0 md:p-0'}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Dollar Per Input Token</TableHead>
              <TableHead>Dollar Per Output Token</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {list?.map((model) => (
              <TableRow key={model.id}>
                <TableCell>{model.name}</TableCell>
                <TableCell>${scientificNumConvert(model.dollarPerInputToken)}</TableCell>
                <TableCell>${scientificNumConvert(model.dollarPerOutputToken)}</TableCell>
                <TableCell>
                  <Button size='sm' className={'rounded-2xl p-4 font-semibold'}>
                    <Link to={`/embedding-models/${model.id}`}>View</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      {me.role === USER_TYPE.admin && (
        <CardFooter className='justify-center border-t p-1'>
          <Button size='sm' variant='ghost' className='gap-1'>
            <PlusCircle className='h-3.5 w-3.5' />
            <Link to={`/ai-providers/${aiProviderId}/embedding-models/new`}>Add Embedding-Model</Link>
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default EmbeddingModelTable;
