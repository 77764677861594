import React from 'react';
import { ActionFunction, Form, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { Scenario } from '../types';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  const formData = await request.formData();
  const avatarId = formData.get('avatarId');

  let config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  if (!params.scenarioId) return;

  try {
    await axios.delete(`${backendUrl}/scenarios/${params.scenarioId}`, config);
    return redirect(`/avatars/${avatarId}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioDelete() {
  const scenario = useLoaderData() as Scenario;
  return (
    <Form method='post' action='destroy'>
      <Input id='avatarId' name='avatarId' readOnly type={'hidden'} value={scenario.avatarId} />

      <Button type='submit' variant='destructive'>
        Delete
      </Button>
    </Form>
  );
}
