import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { IMessageToDoll } from '../types';
import SelectWithImage from "@/components/SelectWithImage";

const MessageToDollFast: React.FC<IMessageToDoll> = ({ selectedDollId, chatId, dolls, doToggleDoll }) => {
  const [values, setValues] = useState<any>({
    name: '',
    macAddress: '',
    dollId: '',
    selectedDoll: {},
  });
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const formRef: React.RefObject<any> = useRef();

  useEffect(() => {
    if (sendingForm) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
    }
  }, [sendingForm]);

  const handleSubmit = async (e: React.SyntheticEvent | any) => {
    e.preventDefault();

    const localStorageToken = localStorage.getItem('token');
    let config = {
      headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
    };

    const formData = new FormData(formRef.current);
    const body = Object.fromEntries(formData);

    if (isDisconnecting) {
      // @ts-ignore
      body.chatId = null;
    }

    if (!body || !values.dollId) {
      setSendingForm(false);
      return;
    }

    try {
      await axios.patch(`${backendUrl}/dolls/${values.dollId}`, body, config);
    } catch (error) {
      console.log('error', error);
    } finally {
      doShowHideDoll();
      setSendingForm(false);
    }
  };

  const doShowHideDoll = () => {
    if (isDisconnecting) {
      doToggleDoll(undefined);
    } else {
      doToggleDoll(values.selectedDoll);
    }
  };

  const assignValues = (dollId: string) => {
    const options = createOptions();

    let findDoll;

    if (dollId === 'disconnect') {
      findDoll = options.find((option) => option.value === selectedDollId);
    } else {
      findDoll = options.find((option) => option.value === dollId);
    }

    if (!findDoll) return;

    setValues({
      name: findDoll.name,
      dollId: findDoll.id,
      macAddress: findDoll.macAddress,
      selectedDoll: findDoll,
    });
  };

  const createOptions = () => {
    let res: any[] = [
      {
        label: 'Disconnected',
        value: 'disconnect',
      },
    ];

    dolls?.forEach((doll) => {
      res.push({
        ...doll,
        label: doll.name,
        value: doll.id,
        id: doll.id,
        macAddress: doll.macAddress,
        imgUrl: 'dolls',
        picture: doll.picture
      });
    });
    return res;
  };

  const doHandleChange = (optionId: string) => {
    if (optionId === 'disconnect') {
      setIsDisconnecting(true);
    } else {
      setIsDisconnecting(false);
    }

    assignValues(optionId);
    setSendingForm(true);
  };

  return (
    <Fragment>
      <div className={'text-sm w-full flex-row items-start gap-0.5'}>
        <Form ref={formRef} onSubmit={handleSubmit} method='post' id='doll-form'>
          <SelectWithImage
              label={'Doll'}
              disabled={sendingForm}
              name={'dollId'}
              options={createOptions()}
              defaultValue={selectedDollId ?? 'disconnect'}
              onChange={doHandleChange}
          />
          <input name='chatId' value={chatId} readOnly hidden />
          <input name='macAddress' value={values.macAddress} readOnly hidden />
          <input name='name' value={values.name} readOnly hidden />
        </Form>
      </div>
    </Fragment>
  );
};

export default MessageToDollFast;
