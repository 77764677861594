import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ActionFunction, Form, Link, redirect, useLoaderData, useParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { SttProvider } from '@/types';
import { getPicture } from '@/utils/getPicture';

import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');
  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  const formData = await request.formData();
  const body: any = Object.fromEntries(formData);

  if (!body.apiKey) {
    delete body.apiKey;
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  if (!params.sttProviderId) return;

  try {
    await axios.patch(`${backendUrl}/stt-providers/${params.sttProviderId}`, body, config);
    return redirect(`/stt-providers`);
  } catch (error) {
    console.log('error', error);
  }
};

const SttProviderEdit: React.FC = () => {
  const provider = useLoaderData() as SttProvider;
  const { sttProviderId } = useParams();

  const [recommended, setRecommended] = useState(provider.recommended);
  const [dollarPer, setDollarPer] = useState<number | string>(scientificNumConvert(provider.dollarPerSecond));
  const [name, setName] = useState(provider.name);
  const [apiKey, setApiKey] = useState(provider.apiKey || '');
  const [hostName, setHostName] = useState(provider.hostName);
  const [image, setImage] = useState<FileList | any>('');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>STT-Provider Edit {provider.name}</CardTitle>
      </CardHeader>
      <Form method='post' id='stt-provider-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerSecond'>Dollar per second</Label>
              <Input
                id='dollarPerSecond'
                name='dollarPerSecond'
                className='w-full'
                placeholder='Dollar per second'
                required
                type='number'
                step='0.00000001'
                defaultValue={dollarPer}
                onChange={(e) => setDollarPer(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='apiKey'>API Key {sttProviderId ? '(Optional)' : '(Required)'}</Label>
              <Input id='apiKey' name='apiKey' placeholder='API Key' value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='hostname'>Hostname</Label>
              <Input
                id='hostname'
                name='hostname'
                placeholder='Hostname'
                required
                defaultValue={hostName}
                onChange={(e) => setHostName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='file-button'>Picture</Label>
              <div>
                {image && typeof image !== 'string' ? (
                  <img alt='stt-provider-picture' className='h-10 w-10' src={URL.createObjectURL(image[0])} />
                ) : !provider.picture ? (
                  <img alt='stt-provider-picture' className='h-10 w-10' src='https://placehold.co/200x200?text=No+Picture' />
                ) : (
                  ''
                )}

                {provider.picture && !image && (
                  <img
                    className='h-10 w-10'
                    src={getPicture(provider, 'stt-providers', false)}
                    srcSet={getPicture(provider, 'stt-providers', true)}
                    alt=''
                  />
                )}
              </div>
              <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/stt-providers/${sttProviderId}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SttProviderEdit;
