import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter, defer, redirect } from 'react-router-dom';
import SignIn from './components/SignIn';
import axios, { AxiosError } from 'axios';
import { ToastProvider } from '@radix-ui/react-toast';
import { Message } from '@/types';

import ProtectedRoutes from './components/ProtectedRoutes';

import Avatars from './avatar/Avatars';
import AvatarView from './avatar/AvatarView';
import AvatarNew, { action as avatarNewAction } from './avatar/AvatarNew';
import AvatarEdit, { action as avatarEditAction } from './avatar/AvatarEdit';
import { action as avatarDeleteAction } from './avatar/AvatarDelete';

import ScenarioView from './scenario/ScenarioView';
import ScenarioNew, { action as scenarioNewAction } from './scenario/ScenarioNew';
import ScenarioEdit, { action as scenarioEditAction } from './scenario/ScenarioEdit';
import { action as scenarioDeleteAction } from './scenario/ScenarioDelete';

import Messages from './message/Messages';
import ChatView from './chat/ChatView';

import MessageNew, { action as massageNewAction } from './message/MessageNew';
import { action as messageDeleteAction } from './message/MessageDelete';

import { action as chatNewAction } from './chat/ChatNew';
import ChatMessages from './chat/ChatMessages';
import { action as chatDeleteAction } from './chat/ChatDelete';

import Dolls from './doll/Dolls';
import DollView from './doll/DollView';
import DollEdit, { action as dollEditAction } from './doll/DollEdit';
import { action as dollDeleteAction } from './doll/DollDelete';

import Layout from './components/Layout';
import Home from './Home';
import Chats from './chat/Chats';
import MessageDetails from './message/MessageDetails';

import UserView from './user/UserView';
import UserEdit, { action as userEditAction } from './user/UserEdit';

import NotFound from './404';
import PropertyError from './PropertyError';

import SttProviderList from '@/sttProvider/SttProviderList';

import ChatCompletionJobView from '@/chatCompletionJob/ChatCompletionJobView';

import SttProviderNew from '@/sttProvider/SttProviderNew';
import SttProviderView from '@/sttProvider/SttProviderView';
import SttProviderEdit from '@/sttProvider/SttProviderEdit';
import { action as editSttProvider } from './sttProvider/SttProviderEdit';
import { action as deleteSttProvider } from './sttProvider/SttProviderDelete';
import { action as newSttProvider } from './sttProvider/SttProviderNew';

import AiProviderView from '@/aiProvider/AiProviderView';
import AiProviderNew from '@/aiProvider/AiProviderNew';

import AiProviderList from '@/aiProvider/AiProviderList';
import AiProviderEdit from '@/aiProvider/AiProviderEdit';
import { action as submitEditAiProvider } from './aiProvider/AiProviderEdit';
import { action as newAiProviderAction } from './aiProvider/AiProviderNew';
import { action as aiProviderDeleteAction } from './aiProvider/AiProviderDelete';

import ChatModelView from '@/chatModel/ChatModelView';
import ChatModelEdit from '@/chatModel/ChatModelEdit';
import ChatModelNew from '@/chatModel/ChatModelNew';
import { action as editChatModel } from './chatModel/ChatModelEdit';
import { action as deleteChatModel } from './chatModel/ChatModelDelete';
import { action as newChatModel } from './chatModel/ChatModelNew';

import EmbeddingModelView from '@/embeddingModel/EmbeddingModelView';
import EmbeddingModelEdit from '@/embeddingModel/EmbeddingModelEdit';
import EmbeddingModelNew from '@/embeddingModel/EmbeddingModelNew';
import { action as editEmbeddingModel } from './embeddingModel/EmbeddingModelEdit';
import { action as deleteEmbeddingModel } from './embeddingModel/EmbeddingModelDelete';
import { action as newEmbeddingModel } from './embeddingModel/EmbeddingModelNew';

import TtsProviderEdit from '@/ttsProvider/TtsProviderEdit';
import TtsProviderNew from '@/ttsProvider/TtsProviderNew';
import TtsProviderView from '@/ttsProvider/TtsProviderView';
import { action as editTtsProvider } from './ttsProvider/TtsProviderEdit';
import { action as deleteTtsProvider } from './ttsProvider/TtsProviderDelete';
import { action as createTtsProvider } from './ttsProvider/TtsProviderNew';
import ChatEmbeddingJobView from './chatEmbeddingJob/ChatEmbeddingJobView';
import ChatSTTJobView from './sttJob/ChatSTTJobView';
import ChatTTSJobView from './ttsJobs/ChatTTSJobView';
import TtsProviderList from '@/ttsProvider/TtsProviderList';

import TtsVoiceView from '@/ttsVoice/TtsVoiceView';
import TtsVoiceEdit from '@/ttsVoice/TtsVoiceEdit';
import TtsVoiceNew from '@/ttsVoice/TtsVoiceNew';
import { action as editTtsVoice } from './ttsVoice/TtsVoiceEdit';
import { action as deleteTtsVoice } from './ttsVoice/TtsVoiceDelete';
import { action as newTtsVoice } from './ttsVoice/TtsVoiceNew';
import { setLastPath } from './utils/common';
import TtsVoiceList from './ttsVoice/TtsVoiceList';
import ChatModelList from './chatModel/ChatModelList';
import { Services } from './services/services';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';
export const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

const getData = async (path: string) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };
  try {
    const response = await axios.get(backendUrl + path, headers);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        return redirect('/signin');
      }
    }
  }
};

const router = createBrowserRouter([
  {
    element: <ProtectedRoutes />,
    errorElement: <PropertyError />,

    loader: async () => {
      return getData(`/users/me`);
    },
    id: 'me',
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: '/',
            element: <Home />,
            loader: async () => {
              const [dolls, chats] = await Promise.all([getData(`/dolls`), getData(`/chats`)]);
              return defer({ dolls, chats });
            },
          },

          {
            path: '/users/:userId',
            element: <UserView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/users/${params.userId}`),
          },
          {
            path: '/users/:userId/edit',
            element: <UserEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/users/${params.userId}`),
            action: userEditAction,
          },

          {
            path: '/users/:userId/refresh',
            action: userEditAction,
          },

          {
            path: '/services',
            element: <Services />,
            loader: async () => {
              const [ttsVoices, sttProviders, chatModels] = await Promise.all([
                getData(`/tts-voices`),
                getData(`/stt-providers`),
                getData(`/chat-models`),
              ]);
              return defer({ ttsVoices, sttProviders, chatModels });
            },
          },
          {
            path: '/ai-providers/:aiProviderId/destroy',
            action: aiProviderDeleteAction,
          },
          {
            path: '/ai-providers',
            errorElement: <PropertyError />,
            element: <AiProviderList />,
            loader: async () => getData('/ai-providers'),
          },
          {
            path: '/ai-providers/new',
            errorElement: <PropertyError />,
            element: <AiProviderNew />,
            action: newAiProviderAction,
          },
          {
            path: '/ai-providers/:aiProviderId/edit',
            element: <AiProviderEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/ai-providers/${params.aiProviderId}`),
            action: submitEditAiProvider,
          },
          {
            path: '/ai-providers/:aiProviderId',
            element: <AiProviderView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/ai-providers/${params.aiProviderId}`),
          },
          {
            path: '/ai-providers/:aiProviderId/chat-models/new',
            element: <ChatModelNew />,
            errorElement: <PropertyError />,
            action: newChatModel,
          },
          {
            path: '/chat-models',
            element: <ChatModelList />,
            loader: async () => getData(`/chat-models`),
          },
          {
            path: '/chat-models/:modelId',
            element: <ChatModelView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/chat-models/${params.modelId}`),
          },
          {
            path: '/chat-models/:modelId/edit',
            element: <ChatModelEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/chat-models/${params.modelId}`),
            action: editChatModel,
          },
          {
            path: '/chat-models/:modelId/destroy',
            action: deleteChatModel,
          },
          {
            path: '/chat-model-parameters',
            loader: async () => getData(`/chat-model-parameters`),
          },
          {
            path: '/embedding-models',
            loader: async () => getData(`/embedding-models`),
          },
          {
            path: '/ai-providers/:aiProviderId/embedding-models/new',
            element: <EmbeddingModelNew />,
            errorElement: <PropertyError />,
            action: newEmbeddingModel,
          },
          {
            path: '/embedding-models/:modelId/destroy',
            action: deleteEmbeddingModel,
          },
          {
            element: <EmbeddingModelView />,
            path: '/embedding-models/:modelId',
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/embedding-models/${params.modelId}`),
          },
          {
            element: <EmbeddingModelEdit />,
            path: '/embedding-models/:modelId/edit',
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              const [aiProviders, embedModel] = await Promise.all([
                getData(`/ai-providers`),
                getData(`/embedding-models/${params.modelId}`),
              ]);
              return defer({ aiProviders, embedModel });
            },
            action: editEmbeddingModel,
          },
          {
            path: '/tts-providers',
            element: <TtsProviderList />,
            errorElement: <PropertyError />,
            loader: async () => getData(`/tts-providers`),
          },
          {
            path: '/tts-providers/new',
            element: <TtsProviderNew />,
            action: createTtsProvider,
          },
          {
            path: '/tts-providers/:ttsProviderId/edit',
            element: <TtsProviderEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/tts-providers/${params.ttsProviderId}`),
            action: editTtsProvider,
          },
          {
            path: '/tts-providers/:ttsProviderId',
            element: <TtsProviderView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/tts-providers/${params.ttsProviderId}`),
          },
          {
            path: '/tts-providers/:ttsProviderId/destroy',
            action: deleteTtsProvider,
          },
          {
            path: '/tts-providers/:ttsProviderId/tts-voices/new',
            element: <TtsVoiceNew />,
            errorElement: <PropertyError />,
            action: newTtsVoice,
          },

          {
            path: '/tts-voices',
            element: <TtsVoiceList />,
            errorElement: <PropertyError />,
            loader: async () => getData(`/tts-voices`),
          },
          {
            path: `/tts-voices/:ttsVoiceId`,
            element: <TtsVoiceView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/tts-voices/${params.ttsVoiceId}`),
          },
          {
            path: `/tts-voices/:ttsVoiceId/edit`,
            element: <TtsVoiceEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              const [ttsVoice, ttsProviders] = await Promise.all([getData(`/tts-voices/${params.ttsVoiceId}`), getData(`/tts-providers`)]);
              return defer({ ttsVoice, ttsProviders });
            },
            action: editTtsVoice,
          },
          {
            path: '/tts-voices/:ttsVoiceId/destroy',
            action: deleteTtsVoice,
          },

          {
            path: '/stt-providers',
            element: <SttProviderList />,
            errorElement: <PropertyError />,
            loader: async () => getData(`/stt-providers`),
          },
          {
            path: '/stt-providers/new',
            element: <SttProviderNew />,
            errorElement: <PropertyError />,
            action: newSttProvider,
          },
          {
            path: '/stt-providers/:sttProviderId',
            element: <SttProviderView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/stt-providers/${params.sttProviderId}`),
          },
          {
            path: '/stt-providers/:sttProviderId/edit',
            element: <SttProviderEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/stt-providers/${params.sttProviderId}`),
            action: editSttProvider,
          },
          {
            path: '/stt-providers/:sttProviderId/destroy',
            action: deleteSttProvider,
          },

          {
            path: '/chat-completion-jobs/:chatCompletionJobId',
            errorElement: <PropertyError />,
            element: <ChatCompletionJobView />,
            loader: async ({ params }) => {
              return getData(`/chat-completion-jobs/${params.chatCompletionJobId}`);
            },
          },

          {
            path: '/embedding-jobs/:JobId',
            errorElement: <PropertyError />,
            element: <ChatEmbeddingJobView />,
            loader: async ({ params }) => {
              console.log(params.JobId);
              return getData(`/embedding-jobs/${params.JobId}`);
            },
          },

          {
            path: '/tts-jobs/:JobId',
            errorElement: <PropertyError />,
            element: <ChatTTSJobView />,
            loader: async ({ params }) => {
              return getData(`/tts-jobs/${params.JobId}`);
            },
          },

          {
            path: '/stt-jobs/:JobId',
            errorElement: <PropertyError />,
            element: <ChatSTTJobView />,
            loader: async ({ params }) => {
              return getData(`/stt-jobs/${params.JobId}`);
            },
          },

          {
            path: '/avatars',
            element: <Avatars />,
            errorElement: <PropertyError />,
            loader: async () => {
              const [avatars, sttProviders] = await Promise.all([getData(`/avatars`), getData(`/stt-providers`)]);
              return defer({ avatars, sttProviders });
            },
            action: chatNewAction,
          },
          {
            path: '/avatars/:avatarId',
            element: <AvatarView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/avatars/${params.avatarId}`),
          },
          {
            path: '/avatars/new',
            element: <AvatarNew />,
            action: avatarNewAction,
            loader: async () => getData(`/tts-providers`),
          },
          {
            path: '/avatars/:avatarId/edit',
            element: <AvatarEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              const [avatar, ttsProviders] = await Promise.all([getData(`/avatars/${params.avatarId}`), getData(`/tts-providers`)]);
              return defer({ avatar, ttsProviders });
            },
            action: avatarEditAction,
          },
          {
            path: '/avatars/:avatarId/destroy',
            action: avatarDeleteAction,
          },

          // {
          //   path: '/scenarios',
          //   element: <Scenarios />,
          //   loader: async () => getData(`/scenarios`),
          // },
          {
            path: '/scenarios/:scenarioId',
            element: <ScenarioView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/scenarios/${params.scenarioId}`),
          },

          // {
          //   path: '/scenarios/:scenarioId/chats/new',
          //   loader: async ({ params }) => getData(`/scenarios/${params.scenarioId}`),
          //   element: <ChatNew />,
          //   action: chatNewAction,
          // },

          {
            path: '/avatars/:avatarId/scenarios/new',
            element: <ScenarioNew />,
            action: scenarioNewAction,
            loader: async () => getData('/ai-providers'),
          },
          {
            path: '/scenarios/:scenarioId/edit',
            element: <ScenarioEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              const [scenario, aiProviders] = await Promise.all([getData(`/scenarios/${params.scenarioId}`), getData(`/ai-providers`)]);
              return defer({ scenario, aiProviders });
            },
            action: scenarioEditAction,
          },
          {
            path: '/scenarios/:scenarioId/destroy',
            errorElement: <PropertyError />,
            action: scenarioDeleteAction,
          },

          {
            path: '/dolls/:dollId',
            element: <DollView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/dolls/${params.dollId}`),
          },
          {
            path: '/dolls/:dollId/edit',
            element: <DollEdit />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/dolls/${params.dollId}`),
            action: dollEditAction,
          },
          {
            path: '/dolls/:dollId/destroy',
            action: dollDeleteAction,
          },
          {
            path: '/messages/:messageId',
            element: <MessageDetails />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              let message: Message;
              message = await getData(`/messages/${params.messageId}`);

              const getMsgAdditionalData = async () => {
                let res: any = {
                  message,
                  ttsJob: null,
                  sttJob: null,
                  embeddingJob: null,
                  chatCompletionJob: null
                };
                if (message.ttsJob) res.ttsJob = await getData(`/tts-jobs/${message.ttsJob.id}`);
                if (message.sttJob) res.sttJob = await getData(`/stt-jobs/${message.sttJob.id}`);
                if (message.embeddingJob) res.embeddingJob = await getData(`/embedding-jobs/${message.embeddingJob.id}`);
                if (message.chatCompletionJob) res.chatCompletionJob = await getData(`/chat-completion-jobs/${message.chatCompletionJob.id}`);

                return res;
              };
              const loadData = await getMsgAdditionalData();

              return defer({ ...loadData });
            },
          },
          {
            path: '/messages/:messageId/destroy',
            action: messageDeleteAction,
          },

          {
            path: '/chats/:chatId',
            element: <ChatView />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => getData(`/chats/${params.chatId}`),
          },
          {
            element: <ChatMessages />,
            errorElement: <PropertyError />,
            loader: async ({ params }) => {
              return getData(`/chats/${params.chatId}`);
            },
            id: 'chat',
            children: [
              {
                path: '/chats/:chatId',
                element: <Messages />,
                errorElement: <PropertyError />,
                loader: async ({ params }) => {
                  const [messages, dolls, chatModels, chatCompletionJobs, sttProviders] = await Promise.all([
                    getData(`/messages/?chatId=${params.chatId}`),
                    getData(`/dolls`),
                    getData(`/chat-models`),
                    getData(`/chat-completion-jobs?chatId=${params.chatId}`),
                    getData(`/stt-providers`),
                  ]);

                  return defer({ messages, dolls, chatModels, chatCompletionJobs, sttProviders });
                },
                children: [
                  {
                    path: '/chats/:chatId',
                    element: <MessageNew />,
                    action: massageNewAction,
                  },
                ],
              },
            ],
          },

          {
            path: '/chats/:chatId/destroy',
            action: chatDeleteAction,
          },
        ],
      },
    ],
  },
  {
    path: '/signin',
    element: <SignIn />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <ToastProvider swipeDirection='right'>
      <RouterProvider router={router} />
    </ToastProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
