import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    await axios.post(`${backendUrl}/embedding-models`, body, config);
    return redirect(`/ai-providers/${params.aiProviderId}`);
  } catch (error) {
    console.log('error', error);
  }
};

const EmbeddingModelNew: React.FC = () => {
  const { aiProviderId } = useParams();

  const [recommended, setRecommended] = useState<boolean>(false);

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>New Embedding-Model</CardTitle>
      </CardHeader>
      <Form method='post' id='embedding-model-form'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Name' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='providerModelName'>Provider model name</Label>
              <Input id='providerModelName' name='providerModelName' className='w-full' placeholder='Provider model name' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerInputToken'>Dollar per input token</Label>
              <Input
                id='dollarPerInputToken'
                name='dollarPerInputToken'
                className='w-full'
                placeholder='Dollar Per Input Token'
                required
                step='0.00000001'
                type={'number'}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerOutputToken'>Dollar per output token</Label>
              <Input
                id='dollarPerOutputToken'
                name='dollarPerOutputToken'
                className='w-full'
                placeholder='Dollar per output token'
                required
                type={'number'}
                step='0.00000001'
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>

            <Input id='aiProviderId' name='aiProviderId' readOnly type={'hidden'} value={aiProviderId} />
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/ai-providers/${aiProviderId}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default EmbeddingModelNew;
