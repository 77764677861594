import { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { AiProvider, Scenario } from '@/types';
import { setLastPath } from '@/utils/common';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';

import SelectOptGroup from '@/components/SelectGroup';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);
  try {
    const response = await axios.post(`${backendUrl}/scenarios`, body, config);
    const scenario: Scenario = response.data;
    return redirect(`/scenarios/${scenario.id}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioNew() {
  const aiProviders = useLoaderData() as AiProvider[];

  const [checked, setChecked] = useState(false);
  let { avatarId } = useParams();

  const getOptions = (forChatModels: boolean) => {
    let res: any[] = [];

    aiProviders.forEach((aiProvider) => {
      let newOptionGroup: any = { groupName: aiProvider.name, options: [] };

      const modelsArr: any[] = forChatModels ? aiProvider.chatModels : aiProvider.embeddingModels;

      if (modelsArr && modelsArr.length === 0) {
        return;
      }

      modelsArr.forEach((model) => {
        newOptionGroup.options.push({
          label: model.name,
          value: model.id,
        });
      });

      res.push(newOptionGroup);
    });
    return res;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>New Scenario</CardTitle>
      </CardHeader>
      <Form method='post' id='scenario-form' encType='multipart/form-data'>
        <input type='text' name='avatarId' value={avatarId} readOnly hidden />
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Movie Night' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input
                id='shortDesc'
                name='shortDesc'
                type='text'
                className='w-full'
                placeholder='{{user}} and {{char}} watch a Movie together'
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='greeting'>Greeting</Label>
              <Textarea
                id='greeting'
                name='greeting'
                rows={2}
                className='w-full'
                placeholder='oh {{user}} I am so looking forward to this Movie'
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Description</Label>
              <Textarea required id='description' name='description' rows={20} placeholder='description in w++ format' />
            </div>

            <div className='grid gap-3'>
              <Input id='file-button' name='picture' type='file' className='hidden' />
            </div>

            <div className=' gap-3 hidden'>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} className='hidden' />
            </div>

            <div className=' gap-3 hidden'>
              <SelectOptGroup label={'Chat Model'} options={getOptions(true)} name={'chatModelId'} />
            </div>

            <div className=' gap-3 hidden'>
              <SelectOptGroup label={'Embedding Model'} options={getOptions(false)} name={'embeddingModelId'} />
            </div>

            <div className='grid gap-3'>
              <Input id='temperature' name='temperature' type='number' step='0.00000001' defaultValue='0.2' required className='hidden' />
            </div>

            <div className='grid gap-3'>
              <Input id='topP' name='topP' type='number' defaultValue='1' required step='0.00000001' className='hidden' />
            </div>

            <div className='grid gap-3'>
              <Input
                id='frequencyPenalty'
                name='frequencyPenalty'
                type='number'
                step='0.00000001'
                defaultValue='0.2'
                required
                className='hidden'
              />
            </div>

            <div className='grid gap-3'>
              <Input
                id='presencePenalty'
                name='presencePenalty'
                type='number'
                step='0.00000001'
                defaultValue='0.2'
                required
                className='hidden'
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/avatars/${avatarId}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
