import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { SttProvider } from '@/types';

import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');
  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    await axios.post(`${backendUrl}/stt-providers`, body, config);
    return redirect(`/stt-providers`);
  } catch (error) {
    console.log('error', error);
  }
};

const SttProviderNew: React.FC = () => {
  const provider = useLoaderData() as SttProvider;

  const [recommended, setRecommended] = useState(false);
  const [image, setImage] = useState<FileList | any>('');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>STT-Provider New</CardTitle>
      </CardHeader>
      <Form method='post' id='stt-provider-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Name' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerSecond'>Dollar per second</Label>
              <Input
                id='dollarPerSecond'
                name='dollarPerSecond'
                className='w-full'
                placeholder='Dollar per second'
                required
                type={'number'}
                step='0.00000001'
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='apiKey'>API Key</Label>
              <Input id='apiKey' name='apiKey' placeholder={'API Key'} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='file-button'>Picture</Label>

              <div>
                {image && typeof image !== 'string' ? (
                  <img alt='tts-provider-picture' className={'h-10 w-10'} src={URL.createObjectURL(image[0])} />
                ) : (
                  <img alt='tts-provider-picture' className={'h-10 w-10'} src={`https://placehold.co/65x64?text=No+Picture`} />
                )}
              </div>

              <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/stt-providers`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SttProviderNew;
