import { Fragment, useEffect, useRef, useState } from 'react';
import { ToastProvider, ToastViewport } from '@radix-ui/react-toast';
import { useRouteLoaderData } from 'react-router-dom';
import mqtt from 'mqtt';

import BrainIcon from 'mdi-react/BrainIcon';
import EarHearingIcon from 'mdi-react/EarHearingIcon';
import CurrencyEthIcon from 'mdi-react/CurrencyEthIcon';
import AccountVoiceIcon from 'mdi-react/AccountVoiceIcon';
import VectorPolylinePlusIcon from 'mdi-react/VectorPolylinePlusIcon';

import { JobEvent, User } from '@/types';
import JobEventToast from './ui/job-event-toast';

const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

const Toast: React.FC = () => {
  const me = useRouteLoaderData('me') as User;
  const mqttClientRef = useRef<mqtt.MqttClient | null>(null);
  const [jobEvents, setJobEvents] = useState<JobEvent[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}/jobEvents`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const jobEvent: JobEvent = JSON.parse(message.toString());

          if (jobEvent.jobStatus === 'completed') {
            setIsOpen(false);
          } else if (jobEvent.jobStatus === 'failed' || jobEvent.jobStatus === 'active') {
            setIsOpen(true);
          }

          setJobEvents((prevJobEvents) => {
            if (jobEvent.jobStatus === 'active') {
              return [...prevJobEvents, jobEvent];
            } else if (jobEvent.jobStatus === 'completed') {
              return prevJobEvents.filter((event) => event.jobId !== jobEvent.jobId);
            } else {
              return prevJobEvents;
            }
          });
        }
      };

      client.on('message', handleMessage);

      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, [me.id]); // Only re-run the effect if the user ID changes

  return (
    <ToastProvider swipeDirection='right'>
      {jobEvents.map((event) => (
        <Fragment key={`${event.jobId}-${event.resourceId}`}>
          {event.resourceName === 'sttJobs' && (
            <JobEventToast
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              isOpen={isOpen}
              jobStatus={event.jobStatus}
              description='Speech To Text'
              icon={<EarHearingIcon size={18} />}
            />
          )}
          {event.resourceName === 'chatCompletionJobs' && (
            <JobEventToast
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              isOpen={isOpen}
              jobStatus={event.jobStatus}
              description='Chat Completion'
              icon={<BrainIcon size={18} />}
              id={event.resourceId}
            />
          )}
          {event.resourceName === 'embeddingJobs' && (
            <JobEventToast
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              isOpen={isOpen}
              jobStatus={event.jobStatus}
              description='Embeddings'
              icon={<VectorPolylinePlusIcon size={18} />}
            />
          )}
          {event.resourceName === 'ttsJobs' && (
            <JobEventToast
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              isOpen={isOpen}
              jobStatus={event.jobStatus}
              description='Text To Speech'
              icon={<AccountVoiceIcon size={18} />}
            />
          )}
          {event.resourceName === 'paymentJobs' && (
            <JobEventToast
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              isOpen={isOpen}
              jobStatus={event.jobStatus}
              description='ETH Transaction'
              icon={<CurrencyEthIcon size={18} />}
            />
          )}
        </Fragment>
      ))}
      <ToastViewport className='fixed bottom-0 left-0 p-6' />
    </ToastProvider>
  );
};

export default Toast;
