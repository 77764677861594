import React from 'react';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { TtsProvider, User } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { Plus } from 'lucide-react';
import { getPicture } from "@/utils/getPicture";
import { PICTURE_SIZE, USER_TYPE } from '@/constants';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';

const TtsProviderList: React.FC = () => {
  const list = useLoaderData() as TtsProvider[];
  const me = useRouteLoaderData('me') as User;

  return (
    <>
      {me.role === USER_TYPE.admin && (
        <div className='ml-auto flex items-center justify-between w-full pe-4'>
          <div>
            <h1 className={'font-bold text-2xl'}>TTS-Providers</h1>
            <h3>Overview</h3>
          </div>

          <Button size='sm' className='h-8 gap-2 pe-6 rounded-2xl font-semibold'>
            <Plus className='h-3.5 w-3.5' />
            <Link to='/tts-providers/new'>TTS-Provider</Link>
          </Button>
        </div>
      )}
      {list.map((provider) => (
        <Card key={provider.id} x-chunk='dashboard-06-chunk-0' className='rounded-none'>
          <CardHeader className='flex space-y-0 flex-row items-center bg-[#d3dee4] gap-3 p-0 md:p-0'>
            <div className='grid gap-0.5'>
              <img
                src={getPicture(provider, 'tts-providers', false, PICTURE_SIZE.semiMedium)}
                srcSet={getPicture(provider, 'tts-providers', true, PICTURE_SIZE.semiMedium)}
                alt={''}
              />
            </div>
            <div className='grid gap-0.5'>
              <CardTitle className='group flex items-center gap-2 text-lg'>{provider.name}</CardTitle>
              <CardDescription>Dollar per character: ${scientificNumConvert(provider.dollarPerCharacter)}</CardDescription>
            </div>
            <div className='ml-auto flex items-center gap-1 me-4'>
              <Button size='sm' className={'rounded-2xl p-4 font-semibold'}>
                <Link to={`/tts-providers/${provider.id}`}>View</Link>
              </Button>
            </div>
          </CardHeader>

          <CardContent className={'p-0 md:p-0 rounded-none'}>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Recommended</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {provider.ttsVoices.map((voice) => {
                  return (
                    <TableRow key={voice.id}>
                      <TableCell>{voice.name}</TableCell>
                      <TableCell>{voice.recommended.toString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default TtsProviderList;
