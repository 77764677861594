import React from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { ITtsVoiceTable, User } from '@/types';
import { USER_TYPE } from '@/constants';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';

const TtsVoicesTable: React.FC<ITtsVoiceTable> = ({ list, ttsProviderId }) => {
  const me = useRouteLoaderData('me') as User;

  return (
    <Card className='rounded-none'>
      <CardHeader className='flex flex-row items-start bg-muted/50 gap-3'>
        <div className='grid gap-0.5'>
          <CardTitle className='group flex items-center gap-2 text-lg'>TTS Voices</CardTitle>
        </div>
      </CardHeader>
      <CardContent className={'p-0 md:p-0'}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Recommended</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {list?.map((voice) => (
              <TableRow key={voice.id}>
                <TableCell>{voice.name}</TableCell>
                <TableCell>{voice.recommended?.toString()}</TableCell>
                <TableCell>
                  <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                    <Link to={`/tts-voices/${voice.id}`}>View</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      {me.role === USER_TYPE.admin && (
        <CardFooter className='justify-center border-t p-1'>
          <Button size='sm' variant='ghost' className='gap-1'>
            <PlusCircle className='h-3.5 w-3.5' />
            <Link to={`/tts-providers/${ttsProviderId}/tts-voices/new`}>Add Voice</Link>
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default TtsVoicesTable;
