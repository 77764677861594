import classNames from 'classnames';
import { Ear, LayoutDashboard, Speech } from 'lucide-react';
import BrainIcon from 'mdi-react/BrainIcon';
import Head from 'mdi-react/HeadOutlineIcon';
import { Link, Location } from 'react-router-dom';

export const Sidebar = ({ location, isAdmin }: { location: Location; isAdmin: boolean }) => {
  return (
    <nav className='grid gap-1 text-sm text-muted-foreground dark:text-white'>
      <Link
        to='/avatars'
        className={classNames('flex items-center gap-1', {
          'font-semibold text-primary': location.pathname.includes('/avatars'),
        })}
      >
        <Head className='h-4 w-4' /> Avatars
      </Link>

      <Link
        to='/'
        className={classNames('mb-3 flex items-center gap-1', {
          'font-semibold text-primary': location.pathname === '/',
        })}
      >
        <LayoutDashboard className='h-4 w-4' /> Dashboard
      </Link>

      {isAdmin ? (
        <>
          <Link
            to='/ai-providers'
            className={classNames('flex items-center gap-1', {
              'font-semibold text-primary': location.pathname.includes('/ai-providers'),
            })}
          >
            <BrainIcon className='h-4 w-4' /> AI Providers
          </Link>
          <Link
            to='/tts-providers'
            className={classNames('flex items-center gap-1', {
              'font-semibold text-primary': location.pathname.includes('/tts-providers'),
            })}
          >
            <Speech className='h-4 w-4' /> TTS Providers
          </Link>
          <Link
            to='/stt-providers'
            className={classNames('flex items-center gap-1', {
              'font-semibold text-primary': location.pathname.includes('/stt-providers'),
            })}
          >
            <Ear className='h-4 w-4' /> STT Providers
          </Link>
        </>
      ) : (
        <Link
          to='/services'
          className={classNames('flex items-center gap-1', {
            'font-semibold text-primary': location.pathname.includes('/services'),
          })}
        >
          <div className='flex space-x-1'>
            <Ear className='h-4 w-4' />
            <Speech className='h-4 w-4' />
            <BrainIcon className='h-4 w-4' />
          </div>
          <span>Services</span>
        </Link>
      )}
    </nav>
  );
};
