import React from 'react';
import { EmbeddingModel, User } from '@/types';
import { USER_TYPE } from '@/constants';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import EmbeddingModelDelete from '@/embeddingModel/EmbeddingModelDelete';

const EmbeddingModelView: React.FC = () => {
  const model = useLoaderData() as EmbeddingModel;
  const me = useRouteLoaderData('me') as User;

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Embedding-Model Card</CardTitle>
      </CardHeader>
      <CardContent>
        <div className='grid grid-cols-2 md:lg:grid-cols-2 lg:grid-cols-3 gap-2'>
          <div className='p-2 text-sm md:text-base'>Name:</div>
          <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.name}</div>

          <div className='p-2 text-sm md:text-base'>Provider Model Name:</div>
          <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.providerModelName}</div>

          <div className='p-2 text-sm md:text-base'>Dollar Per Input Token:</div>
          <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>${scientificNumConvert(model.dollarPerInputToken)}</div>

          <div className='p-2 text-sm md:text-base'>Dollar Per Output Token:</div>
          <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>${scientificNumConvert(model.dollarPerOutputToken)}</div>

          <div className='p-2 text-sm md:text-base'>Recommended:</div>
          <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.recommended.toString()}</div>
        </div>
      </CardContent>

      {me.role === USER_TYPE.admin && (
        <CardFooter className='flex gap-3'>
          <Button className='gap-1'>
            <Link to={`/embedding-models/${model.id}/edit`}>Edit</Link>
          </Button>
          <EmbeddingModelDelete />
        </CardFooter>
      )}
    </Card>
  );
};

export default EmbeddingModelView;
