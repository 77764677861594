import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { IChatSttProviderEdit } from '@/types';
import SelectWithImage from '@/components/SelectWithImage';

const ChatSttProviderMiniEdit: React.FC<IChatSttProviderEdit> = ({
  chatId,
  dollId,
  sttProviders,
  chatSttProviderId,
  doChangeSttProvider,
}) => {
  const formRef: React.RefObject<any> = useRef();

  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [selectedSttProvider, setSelectedSttProvider] = useState<string | undefined>(chatSttProviderId ?? '');

  useEffect(() => {
    if (sendingForm) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
    }
  }, [sendingForm]);

  const handleSubmit = async (e: React.SyntheticEvent | any) => {
    e.preventDefault();

    const localStorageToken = localStorage.getItem('token');
    let config = {
      headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
    };

    const formData = new FormData(formRef.current);
    const body = Object.fromEntries(formData);

    if (!body || !body.sttProviderId) return;

    try {
      await axios.patch(`${backendUrl}/chats/${chatId}`, body, config);
      doChangeSttProvider(selectedSttProvider);
    } catch (error) {
      console.log('error', error);
    } finally {
      setSendingForm(false);
    }
  };

  const getOptions = () => {
    let res: any[] = [];

    if (!sttProviders) {
      return res;
    }

    sttProviders.forEach((provider) => {
      res.push({
        label: provider.name,
        value: provider.id,
        id: provider.id,
        imgUrl: 'stt-providers',
        picture: provider.picture,
      });
    });
    return res;
  };

  const doHandleChange = (sttProviderId: string) => {
    setSelectedSttProvider(sttProviderId);
    setSendingForm(true);
  };

  return (
    <Fragment>
      <div className={'text-sm w-full flex-row items-start gap-0.5'}>
        <Form ref={formRef} onSubmit={handleSubmit} method='post' id='stt-provider-mini-chat-form'>
          <SelectWithImage
            label={'STT-Provider'}
            disabled={sendingForm}
            name={'sttProviderId'}
            options={getOptions()}
            defaultValue={selectedSttProvider}
            onChange={doHandleChange}
          />
          <input name='sttProviderId' value={selectedSttProvider} readOnly hidden />
          <input name='dollId' value={dollId} readOnly hidden />
        </Form>
      </div>
    </Fragment>
  );
};

export default ChatSttProviderMiniEdit;
