import React from 'react';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { TtsProvider, User } from '@/types';
import { USER_TYPE } from '@/constants';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { getPicture } from "@/utils/getPicture";

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Button } from '@/components/ui/button';
import TtsVoicesTable from '@/ttsVoice/TtsVoicesTable';
import TtsProviderDelete from '@/ttsProvider/TtsProviderDelete';

const TtsProviderView: React.FC = () => {
  const provider = useLoaderData() as TtsProvider;
  const me = useRouteLoaderData('me') as User;

  return (
    <main className='grid flex-1 items-start gap-4 sm:px-0 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <TtsVoicesTable list={provider.ttsVoices} ttsProviderId={provider.id} />
      </div>

      <Card className='rounded-none'>
        <CardHeader>
          <CardTitle>{provider.name}</CardTitle>
        </CardHeader>

        <CardContent>
          <div className={'mb-4'}>
            <img
              src={getPicture(provider, 'tts-providers', false)}
              srcSet={getPicture(provider, 'tts-providers', true)}
              alt=''
            />
          </div>

          <Separator className='my-4' />
          <div className='font-semibold'>Dollar per character</div>
          <span className='text-muted-foreground'>${scientificNumConvert(provider.dollarPerCharacter)}</span>
        </CardContent>

        {me.role === USER_TYPE.admin && (
          <CardFooter className='flex justify-between'>
            <>
              <Button className='gap-1'>
                <Link to={`/tts-providers/${provider.id}/edit`}>Edit</Link>
              </Button>
              <TtsProviderDelete />
            </>
          </CardFooter>
        )}
      </Card>
    </main>
  );
};

export default TtsProviderView;
