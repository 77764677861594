import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { Doll } from '../types';
import { getPicture } from "@/utils/getPicture";

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.dollId) return;
  try {
    await axios.patch(`${backendUrl}/dolls/${params.dollId}`, updates, config);
    return redirect(`/dolls`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function DollEdit() {
  const doll = useLoaderData() as Doll;

  const [image, setImage] = useState<FileList | any>('');


  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit Doll</CardTitle>
      </CardHeader>
      <Form method='post' id='doll-edit-form' encType='multipart/form-data'>
        <input type='hidden' value={doll.macAddress} name='macAddress' />

        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Name' required defaultValue={doll.name} />
            </div>

            <Label htmlFor='file-button'></Label>

            <div className={'mb-4'}>
              {image && typeof image !== 'string' ? (
                <img alt='ai-provider-picture' width={'200px'} src={URL.createObjectURL(image[0])} />
              ) : !doll.picture ? (
                <img alt='ai-provider-picture' width={'200px'} src={`https://placehold.co/200x200?text=No+Picture`} />
              ) : (
                ''
              )}
              {doll.picture && !image &&
                <img
                  className='h-8 w-8 '
                  src={getPicture(doll, 'dolls', false)}
                  srcSet={getPicture(doll, 'dolls', true)}
                  alt=''
              />}
            </div>

            <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/dolls`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
