import { useState, useEffect } from 'react';
import { Button } from './ui/button';

const ThemeToggleButton = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setIsDarkMode(storedTheme === 'dark');
      document.body.classList.toggle('dark', storedTheme === 'dark');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark', !isDarkMode);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className='flex items-center space-x-2'>
      <Button className={'h-auto'} size='sm' variant='ghost' onClick={toggleTheme}>
        <span className='text-gray-900 dark:text-gray-100 text-lg'>{isDarkMode ? '🌞' : '🌙'}</span>
      </Button>
    </div>
  );
};

export default ThemeToggleButton;
