import React, { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { ethers, formatEther } from 'ethers';
import { AlertCircle } from 'lucide-react';
import { RowsIcon } from '@radix-ui/react-icons';

import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import Toast from './Toast';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTrigger } from './ui/sheet';
import ThemeToggleButton from './ThemeToggleButton';
import { LinearLoader } from './ui/linear-loader';
import { User } from '../types';
import { MobileNavigation } from './MobileNavigation';
import { Sidebar } from './Sidebar';
import { USER_TYPE } from '@/constants';
import Logo from '../assets/logo.svg';
import LogoFull from '../assets/logo_full.svg';
import { Button } from './ui/button';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useNavigation();

  const me = useRouteLoaderData('me') as User;

  const weiBalance: BigInt = BigInt(me.weiBalance);
  const minWeiBalance: BigInt = BigInt(10 ** 15);

  const menuContentRef: React.RefObject<any> = useRef(null);

  const [provider, setProvider] = useState<ethers.BrowserProvider | undefined>(undefined);
  const [network, setNetwork] = useState<ethers.Network | undefined>(undefined);

  const isLoading = state === 'loading';
  const adminRoutes = ['/ai-providers', '/tts-providers'];

  const doCloseMenu = () => {
    if (!menuContentRef || !menuContentRef.current) return;
    if (menuContentRef.current.querySelector('.burger-close-btn')) {
      menuContentRef.current.querySelector('.burger-close-btn').click();
    }
  };

  // Initialize provider
  useEffect(() => {
    const initializeProvider = async () => {
      if (window.ethereum) {
        try {
          // Request user accounts
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          // Create a new provider instance
          const providerInstance = new ethers.BrowserProvider(window.ethereum, 'any');
          const network = await providerInstance.getNetwork();
          setProvider(providerInstance);
          setNetwork(network);
        } catch (error) {
          console.error('Error connecting to wallet:', error);
        }
      } else {
        console.error('Ethereum provider not found');
      }
    };

    initializeProvider();
  }, []);

  // Track network changes
  useEffect(() => {
    if (provider) {
      const handleChainChanged = (chainId: string) => {
        console.log('Chain changed:', chainId);
        window.location.reload();
      };

      // Subscribe to chainChanged event directly from window.ethereum
      window.ethereum.on('chainChanged', handleChainChanged);

      // Cleanup listener when component unmounts
      return () => {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, [provider]);

  // track account changes
  useEffect(() => {
    if (provider) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('Accounts changed:', accounts);
        localStorage.removeItem('token');
        window.location.href = '/';
      };

      // Subscribe to accountsChanged event directly from window.ethereum
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      // Cleanup listener when component unmounts
      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, [provider]);

  useEffect(() => {
    const isRestrictedRoute = adminRoutes.some((route) => location.pathname.startsWith(route));

    if (me.role !== USER_TYPE.admin && isRestrictedRoute) {
      navigate('/');
    }
  }, []);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      {isLoading && <LinearLoader />}
      <header className='flex items-center justify-end h-[60px] sm:p-1 md:p-2 text-white bg-cover bg-image-light dark:bg-image-dark relative'>
        <img src={Logo} className='w-fit collapse sm:visible h-8 absolute top-1/2 left-[50%] ps-[118px] transform -translate-x-1/2 -translate-y-1/2' />

        <div className='flex items-center gap-4'>
          <ThemeToggleButton />

          <Link
            to={`/users/${me.id}`}
            className={classNames(
              location.pathname.includes('/users') ? 'text-blue-500 font-semibold' : 'text-white',
              'flex items-center justify-center rounded-full'
            )}
          >
            <Button size='sm' className='rounded-full  h-7'>
              my account
            </Button>
          </Link>

          <Sheet>
            <SheetTrigger className='block md:hidden px-3'>
              <RowsIcon className='' />
            </SheetTrigger>

            <SheetContent ref={menuContentRef}>
              <SheetHeader>
                <SheetDescription className='text-start'>
                  <MobileNavigation location={location} doCloseMenu={doCloseMenu} isAdmin={me.role === USER_TYPE.admin} />
                </SheetDescription>
              </SheetHeader>
            </SheetContent>
          </Sheet>
        </div>
      </header>
      <div className='flex items-center justify-center p-2'>
        <Link className='text-2xl font-semibold' to={`/`}>
          <img src={LogoFull} className='w-fit h-6 ' />
        </Link>
      </div>

      <main className='flex flex-1 flex-col gap-4 bg-muted/40 py-6 px-2 md:gap-8 sm:p-6 md:p-8 lg:p-10'>
        <div className='mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[200px_1fr]'>
          <div className='hidden md:block mt-20'>
            <Sidebar location={location} isAdmin={me.role === USER_TYPE.admin} />
          </div>

          <div className='grid gap-4 md:gap-6'>
            {network?.name !== 'optimism' ? (
              <Alert variant='destructive'>
                <AlertCircle className='h-4 w-4' />
                <AlertTitle>Wrong Network</AlertTitle>
                <AlertDescription>Your are on {network?.name}. please switsh to Optimistic in Metamask</AlertDescription>
              </Alert>
            ) : null}

            {weiBalance < minWeiBalance ? (
              <Alert variant='destructive'>
                <AlertCircle className='h-4 w-4' />
                <AlertTitle>Low Balance</AlertTitle>
                <AlertDescription>
                  Your have {formatEther(me.weiBalance).substring(0, 6)} Ether in your Cipherdolls Wallet. But you need at least{' '}
                  {formatEther(10 ** 15)} Ether. please send some Ether to your Cipherdolls wallet.
                </AlertDescription>
              </Alert>
            ) : null}

            <Outlet />
          </div>
        </div>
      </main>
      <Toast />
    </div>
  );
};

export default Layout;
