import React from 'react';
import { ChatModel, User } from '@/types';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { USER_TYPE } from '@/constants';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { getPicture } from "@/utils/getPicture";

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import ChatModelDelete from '@/chatModel/ChatModelDelete';

const ChatModelView: React.FC = () => {
  const model = useLoaderData() as ChatModel;
  const me = useRouteLoaderData('me') as User;

  return (
    <main className='grid flex-1 items-start gap-4 sm:px-0 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Chat-Model Card</CardTitle>
          </CardHeader>
          <CardContent>
            <div className='grid grid-cols-2 md:lg:grid-cols-2 lg:grid-cols-3 gap-2'>
              <div className='p-2 text-sm md:text-base'>Name:</div>
              <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.name}</div>

              <div className='p-2 text-sm md:text-base'>Dollar Per Input Token:</div>
              <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>${scientificNumConvert(model.dollarPerInputToken)}</div>

              <div className='p-2 text-sm md:text-base'>Dollar Per Output Token:</div>
              <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>
                ${scientificNumConvert(model.dollarPerOutputToken)}
              </div>

              <div className='p-2 text-sm md:text-base'>Context Window:</div>
              <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.contextWindow}</div>

              <div className='p-2 text-sm md:text-base'>Recommended:</div>
              <div className='md:col-span-0 lg:col-span-2 p-2 text-sm md:text-base'>{model.recommended.toString()}</div>
            </div>
          </CardContent>
        </Card>

        {/* Card for Average Time Taken */}
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Average Time Taken</CardTitle>
          </CardHeader>
          <CardContent>
            <div className='p-2 text-sm md:text-base'>{model.aggregateChatCompletions.avgTimeTakenMs} ms</div>
          </CardContent>
        </Card>

        {/* Card for Average USD Cost */}
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Average USD Cost</CardTitle>
          </CardHeader>
          <CardContent>
            <div className='p-2 text-sm md:text-base'>${model.aggregateChatCompletions.avgUsdCost}</div>
          </CardContent>
        </Card>
      </div>

      <Card className='rounded-none'>
        <CardHeader>
          <CardTitle>{model.aiProvider?.name}</CardTitle>
        </CardHeader>

        <CardContent>
          <div>
            <img
              src={getPicture(model?.aiProvider, 'ai-providers', false)}
              srcSet={getPicture(model?.aiProvider, 'ai-providers', true)}
              alt=''
            />
          </div>
        </CardContent>

        {me.role === USER_TYPE.admin && (
          <CardFooter className='flex gap-3'>
            <Button className='gap-1'>
              <Link to={`/chat-models/${model.id}/edit`}>Edit</Link>
            </Button>
            <ChatModelDelete />
          </CardFooter>
        )}
      </Card>
    </main>
  );
};

export default ChatModelView;
