import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLoaderData, useNavigate, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import mqtt from 'mqtt';
import { Chat, Doll, IChatMessages, JobEvent, User } from '../types';
import { PICTURE_SIZE } from '@/constants';
import { getPicture } from '@/utils/getPicture';

import MessageItem from './MessageItem';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Progress } from '@/components/ui/progress';
import { Separator } from '@/components/ui/separator';
import ChatDelete from '@/chat/ChatDelete';
import MessageToDollFast from '@/message/MessageToDollFast';
import ScenarioEditMiniForm from '@/scenario/ScenarioEditMiniForm';
import ChatSttProviderMiniEdit from '@/chat/ChatSttProviderMiniEdit';

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

const Messages: React.FC = () => {
  const navigate = useNavigate();
  const data = useLoaderData() as IChatMessages;
  const { messages, dolls, chatModels, chatCompletionJobs, sttProviders } = data;

  const chat = useRouteLoaderData('chat') as Chat;
  const me = useRouteLoaderData('me') as User;
  let revalidator = useRevalidator();

  const [connectedDoll, setConnectedDoll] = useState<Doll | null>(chat.doll);
  const [connectedSttProvider, setConnectedSttProvider] = useState<string | undefined>(chat?.sttProvider?.id);

  const mqttClientRef = useRef<mqtt.MqttClient | null>(null);

  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}/jobEvents`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const jobEvent: JobEvent = JSON.parse(message.toString());
          if (jobEvent.jobStatus === 'completed') {
            revalidator.revalidate();
          }
        }
      };

      client.on('message', handleMessage);

      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, [me.id]); // Only re-run the effect if the user ID changes

  useEffect(() => {
    const messagesDiv = document.getElementById('messages');
    if (messagesDiv) {
      messagesDiv.scrollTop = messagesDiv.scrollHeight;
      messagesDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  const toggleConnectDoll = (doll?: Doll) => {
    if (doll) {
      setConnectedDoll(doll);
    } else {
      setConnectedDoll(null);
    }
  };

  return (
    <main className='grid flex-1 items-start gap-4 md:gap-6 sm:px-0 sm:py-0 lg:grid-cols-8 xl:grid-cols-8'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-5'>
        <Card className='h-[calc(100vh-250px)] sm:h-[calc(100vh-149px)] flex flex-col rounded-none'>
          <CardContent className='flex-1 overflow-hidden md:p-4'>
            <ScrollArea className='h-full rounded-md border py-2'>
              <div id='messages' className='flex flex-col gap-5 sm:p-4'>
                {messages.map((message) => (
                  <MessageItem key={message.id} message={message} />
                ))}
              </div>
            </ScrollArea>
          </CardContent>
          <CardFooter className={'md:p-4'}>
            <Outlet />
          </CardFooter>
        </Card>
      </div>

      <div className={'lg:col-span-3 h-[auto] flex flex-col gap-4 md:gap-6'}>
        <Card className='rounded-none'>
          <CardHeader className={'px-4 py-4 md:p-4'}>
            <CardTitle className={'text-lg'}>{`${chat.scenario.name} with ${chat.scenario.avatar.name}`}</CardTitle>
            <CardDescription>Messages: {chat._count.messages}</CardDescription>
          </CardHeader>

          <div className={'p-4 text-sm flex flex-row items-start gap-0.5'}>
            <div className='grid gap-0.5'>
              <div className='font-semibold '>Avatar</div>
              <p className='text-sm text-muted-foreground'>{chat.scenario.avatar.name}</p>
              <p className='text-sm text-muted-foreground'>{chat.scenario.avatar.shortDesc}</p>
            </div>

            <div className='ml-auto flex items-center gap-1'>
              <img
                src={getPicture(chat.scenario.avatar, 'avatars', false, PICTURE_SIZE.small)}
                srcSet={getPicture(chat.scenario.avatar, 'avatars', true, PICTURE_SIZE.small)}
                alt=''
              />
            </div>
          </div>

          <Separator />

          <div className={'flex text-sm flex-row items-start p-4 gap-0.5'}>
            <ScenarioEditMiniForm scenario={chat.scenario} chatModels={chatModels} userId={chat.scenario.avatar.userId} />
          </div>

          <Separator />

          <div className={'flex text-sm flex-row items-start p-4 gap-0.5'}>
            <ChatSttProviderMiniEdit
              chatId={chat.id}
              dollId={chat.doll?.id}
              sttProviders={sttProviders}
              doChangeSttProvider={(id?: string) => setConnectedSttProvider(id)}
              chatSttProviderId={connectedSttProvider ?? ''}
            />
          </div>

          <Separator />

          <div className={'p-4 text-sm flex flex-row items-start gap-0.5'}>
            <MessageToDollFast
              selectedDollId={connectedDoll?.id}
              doToggleDoll={(doll?: Doll) => toggleConnectDoll(doll)}
              chatId={chat.id}
              dolls={dolls}
            />
          </div>

          <CardFooter className={'md:px-4 md:py-4'}>
            <ChatDelete />
          </CardFooter>
        </Card>

        <Card className={'h-[350px] overflow-hidden pb-100 rounded-none'}>
          <CardHeader className={'md:px-4 md:py-4'}>
            <CardTitle className={'text-lg'}>Chat Completions</CardTitle>
            <CardDescription>completions: {chat._count.chatCompletionJobs}</CardDescription>
          </CardHeader>
          <ScrollArea className='h-full'>
            <CardContent className={'md:p-0 pb-0'}>
              <Table className={'mb-24'}>
                <TableHeader>
                  <TableRow>
                    <TableHead className={'px-0.5 py-2'}>timeTakenMs</TableHead>
                    <TableHead className={'px-0.5 py-2'}>ContextWindow</TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className={'pb-40 mb-40'}>
                  {chatCompletionJobs.map((chatCompletionJob) => {
                    return (
                      <TableRow
                        key={chatCompletionJob.id}
                        className={'hover:bg-muted/50 cursor-pointer w-full '}
                        onClick={() => navigate(`/chat-completion-jobs/${chatCompletionJob.id}`)}
                      >
                        <TableCell className={'px-0 py-4'}>{chatCompletionJob.timeTakenMs}</TableCell>
                        <TableCell className={'px-0 py-4'}>
                          <Progress
                            value={(chatCompletionJob.inputTokens / chat.scenario.chatModel.maxTokens) * 100}
                            aria-label='Progress'
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </ScrollArea>
        </Card>
      </div>
    </main>
  );
};

export default Messages;
