import React, { useState } from 'react';
import { Form, useSubmit, useActionData } from 'react-router-dom';
import { Terminal } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

interface MessageTextNewProps {
  chatId: string;
}

const MessageTextNew: React.FC<MessageTextNewProps> = ({ chatId }) => {
  const [content, setContent] = useState('');

  const submit = useSubmit();
  const actionData = useActionData() as { error?: string };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    submit(e.currentTarget.form);
    e.currentTarget.form!.reset();
    setContent('');
  };

  return (
    <Form method='post' className='flex items-center'>
      <input name='chatId' defaultValue={chatId} hidden />
      <Input
        id='name'
        name='content'
        type='text'
        className='w-[180px] sm:w-max-[320px] w-full mr-2'
        placeholder='Type your message...'
        value={content}
        onChange={(e) => setContent(e.target.value)}
        required
      />
      <Button type='submit' onClick={handleSubmit} disabled={content.trim() === ''}>
        Send
      </Button>

      {actionData?.error && (
        <Alert variant='destructive'>
          <Terminal className='h-4 w-4' />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{actionData.error}</AlertDescription>
        </Alert>
      )}
    </Form>
  );
};

export default MessageTextNew;
