import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { setLastPath } from '@/utils/common';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    await axios.post(`${backendUrl}/ai-providers`, body, config);
    return redirect(`/ai-providers`);
  } catch (error) {
    console.log('error', error);
  }
};

const AiProviderNew = () => {
  const [image, setImage] = useState<FileList | any>('');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Create AI-Provider</CardTitle>
      </CardHeader>
      <Form method='post' id='ai-provider-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Name' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='apiKey'>API Key</Label>
              <Input id='apiKey' name='apiKey' placeholder={'API Key'} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='basePath'>Base Path</Label>
              <Input id='basePath' name='basePath' className='w-full' placeholder='Base path' required type={'text'} />
            </div>

            <div>
              <Label htmlFor='file-button'>Picture</Label>

              {image && typeof image !== 'string' ? (
                <img alt='ai-provider-picture' className={'h-10 w-10'} src={URL.createObjectURL(image[0])} />
              ) : (
                <img alt='ai-provider-picture' className={'h-10 w-10'} src={`https://placehold.co/65x64?text=No+Picture`} />
              )}
            </div>
            <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/ai-providers`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default AiProviderNew;
