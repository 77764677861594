import React, { useEffect, useState } from 'react';

interface SelectInputProps {
  label?: string;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  options: { value: string; label: string }[];
  defaultValue?: string;
  space?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  id,
  name,
  placeholder,
  className,
  space,
  options,
  defaultValue,
  disabled,
  onChange,
}) => {
  const [defaultVal, setDefaultVal] = useState<string | undefined>(defaultValue ?? '');

  useEffect(() => {
    if (defaultValue) {
      setDefaultVal(defaultValue); // Set the default value if provided
    }
  }, [defaultValue]);

  return (
    <div>
      {label && <label htmlFor={id} className='block text-sm font-medium leading-0 text-gray-900'>
        {label}
      </label>}
      <div className={`${space ? space : 'mt-2'}`}>
        <select
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          defaultValue={defaultVal ?? ''}
          className={`flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 
            text-sm ring-offset-background placeholder:text-muted-foreground block text-ellipsis overflow-hidden
            focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 font-normal
            disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 ${className ? className : ''}
          `}
        >
          <option value='' disabled>
            {placeholder ?? 'Select an option'}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value} className={'font-normal'}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
