import React from 'react';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { AiProvider, User } from '@/types';
import { getPicture } from "@/utils/getPicture";
import { USER_TYPE } from '@/constants';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import ChatModelTable from '@/chatModel/ChatModelTable';
import EmbeddingModelTable from '@/embeddingModel/EmbeddingModelTable';
import AiProviderDelete from '@/aiProvider/AiProviderDelete';

const AiProviderView = () => {
  const me = useRouteLoaderData('me') as User;
  const provider = useLoaderData() as AiProvider;

  return (
    <main className='grid flex-1 items-start gap-4 sm:px-0 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <ChatModelTable list={provider.chatModels} aiProviderId={provider.id} />

        <EmbeddingModelTable list={provider.embeddingModels} aiProviderId={provider.id} />
      </div>

      <Card className='rounded-none'>
        <CardHeader>
          <CardTitle>{provider.name}</CardTitle>
        </CardHeader>

        <CardContent>
          <div>
            <img
              src={getPicture(provider, 'ai-providers', false)}
              srcSet={getPicture(provider, 'ai-providers', true)}
              alt={''}
            />
          </div>
        </CardContent>

        {me.role === USER_TYPE.admin && (
          <CardFooter className='flex justify-between'>
            <>
              <Button className='gap-1'>
                <Link to={`/ai-providers/${provider.id}/edit`}>Edit</Link>
              </Button>
              <AiProviderDelete />
            </>
          </CardFooter>
        )}
      </Card>
    </main>
  );
};

export default AiProviderView;
