import { ActionFunction, Form, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  if (!params.dollId) return;
  try {
    await axios.delete(`${backendUrl}/dolls/${params.dollId}`, config);
    return redirect(`/dolls`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function DollDelete() {
  return (
    <Form method='post' action='destroy'>
      <Button type='submit' variant='destructive'>
        Delete
      </Button>
    </Form>
  );
}
