import React, { Fragment } from 'react';
import { useLoaderData } from 'react-router-dom';
import { backendUrl } from '..';
import { IMessageData } from '../types';

import MessageDelete from './MessageDelete';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import AudioPlayer from './AudioPlayer';
import ChatTTSJobView from '@/ttsJobs/ChatTTSJobView';
import ChatEmbeddingJobView from '@/chatEmbeddingJob/ChatEmbeddingJobView';
import ChatSTTJobView from '@/sttJob/ChatSTTJobView';
import ChatCompletionJobView from "@/chatCompletionJob/ChatCompletionJobView";

const MessageDetails = () => {
  const data = useLoaderData() as IMessageData;
  const { message, ttsJob, sttJob, embeddingJob, chatCompletionJob } = data;

  const goBack = () => {
    window.history.back();
  };

  return (
    <main className='grid flex-1 items-start gap-4 sm:px-0 sm:py-0 lg:grid-cols-8 xl:grid-cols-8'>
      <div className='grid auto-rows-max items-start gap-4 lg:col-span-5'>
        {ttsJob && <ChatTTSJobView ttsJobData={ttsJob} />}

        {embeddingJob && <ChatEmbeddingJobView embeddingJobData={embeddingJob} />}

        {sttJob && <ChatSTTJobView sttJobData={sttJob} sttProvider={message?.chat?.sttProvider} />}

        {chatCompletionJob && <ChatCompletionJobView chatCompletionJob={chatCompletionJob} />}
      </div>

      <div className={'lg:col-span-3 h-[calc(100vh-250px)] sm:h-[calc(100vh-149px)] flex flex-col'}>
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Message</CardTitle>
            <CardDescription>{message.role}</CardDescription>
          </CardHeader>

          <CardContent>
            <div className='whitespace-pre-line'>{message.content}</div>

            {message.content && message.content.length > 0 && message.sttJob &&
            <Fragment>
              <Separator className='my-4' />
              <AudioPlayer audioSrc={`${backendUrl}/messages/${message.id}/audio`}/>
            </Fragment>}

          </CardContent>

          <CardFooter className='flex justify-between'>
            <Button className='gap-1' onClick={goBack}>
              Back
            </Button>
            <MessageDelete />
          </CardFooter>
        </Card>
      </div>
    </main>
  );
};

export default MessageDetails;
