import React, { Fragment, useState } from 'react';
import { Form, Link, useRouteLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { IScenarioMiniEdit, User } from '@/types';
import { getPicture } from '@/utils/getPicture';
import { PICTURE_SIZE } from '@/constants';

import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import SelectInput from '@/components/SelectInput';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { Slider } from '@/components/ui/slider';

const ScenarioEditMiniForm: React.FC<IScenarioMiniEdit> = ({ scenario, chatModels, userId }) => {
  const {
    id,
    avatar: { published: avatarPublished },
    name,
    shortDesc,
    greeting,
    description,
    avatarId,
    chatModel,
    maxTokens,
    embeddingModel,
    temperature: initialTemperature,
    topP: initialTopP,
    frequencyPenalty: initialFrequencyPenalty,
    presencePenalty: initialPresencePenalty,
  } = scenario;

  const me = useRouteLoaderData('me') as User;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [temperature, setTemperature] = useState<number>(initialTemperature);
  const [topP, setTopP] = useState<number>(initialTopP);
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(initialFrequencyPenalty);
  const [presencePenalty, setPresencePenalty] = useState<number>(initialPresencePenalty);

  const handleSubmit = async (e: React.SyntheticEvent | any) => {
    e.preventDefault();
    setSendingForm(true);

    const localStorageToken = localStorage.getItem('token');
    let config = {
      headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
    };

    const formData = new FormData(e.target);
    const body = Object.fromEntries(formData);

    body.temperature = temperature.toString();
    body.topP = topP.toString();
    body.frequencyPenalty = frequencyPenalty.toString();
    body.presencePenalty = presencePenalty.toString();

    if (!body || !id) return;

    try {
      await axios.patch(`${backendUrl}/scenarios/${id}`, body, config);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsEdit(false);
      setSendingForm(false);
    }
  };

  const getOptions = () => {
    let res: any[] = [];

    if (!chatModels) {
      return res;
    }
    chatModels.forEach((model) => {
      res.push({
        label: model.name,
        value: model.id,
      });
    });

    return res;
  };

  return (
    <div className={'w-full'}>
      {!isEdit && (
        <div className={'flex text-sm flex-row items-start gap-0.5'}>
          <div className='grid grid-cols-2 w-full gap-0.5'>
            <div className=' text-sm md:text-sm font-semibold'>Scenario Picture:</div>
            <div className='text-sm ml-auto'>
              {' '}
              <img
                src={getPicture(scenario, 'scenarios', false)}
                srcSet={getPicture(scenario, 'scenarios', true, PICTURE_SIZE.small)}
                alt=''
              />
            </div>

            <div className=' text-sm md:text-sm font-semibold'>Scenario:</div>
            <div className='text-sm'>{name}</div>

            <div className=' text-sm md:text-sm font-semibold'>AI-Provider:</div>
            <div className='text-sm'>{chatModel.aiProvider?.name}</div>

            <div className=' text-sm md:text-sm font-semibold'>Chat Model:</div>
            <div className='text-sm'>{chatModel.name}</div>

            {me.id === userId && (
              <Fragment>
                <div className=' text-sm md:text-sm font-semibold'>Temperature:</div>
                <div className='text-sm'>{temperature}</div>

                <div className=' text-sm md:text-sm font-semibold'>TopP:</div>
                <div className='text-sm'>{topP}</div>

                <div className=' text-sm md:text-sm font-semibold'>Frequency Penalty:</div>
                <div className='text-sm'>{frequencyPenalty}</div>

                <div className=' text-sm md:text-sm font-semibold mb-2'>Presence Penalty:</div>
                <div className='text-sm'>{presencePenalty}</div>
              </Fragment>
            )}

            <div className=' text-sm md:text-sm font-semibold'></div>

            {me.id === userId && (
              <div className='text-sm -mt-9 text-end'>
                <Button size={'sm'} onClick={() => setIsEdit(true)}>
                  Edit
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {isEdit && (
        <>
          <div className='font-semibold mb-2'>Scenario Simple Edit</div>

          <Form method='post' onSubmit={handleSubmit} id='scenario-form' encType='multipart/form-data'>
            <input type='text' name='avatarId' value={avatarId} readOnly hidden />

            <div className='grid gap-4 mb-4'>
              <div className='grid gap-1'>
                <SelectInput
                  className={'h-8'}
                  space={'mt-1'}
                  label={'Chat Model'}
                  options={getOptions()}
                  name={'chatModelId'}
                  defaultValue={chatModel.id}
                  id={'chatModel_dd'}
                />
              </div>

              <div className='grid grid-cols-2 gap-2'>
                <div className='grid gap-2'>
                  <Label htmlFor='temperature'>Temperature</Label>
                  <Slider
                    id='temperature'
                    name='temperature'
                    defaultValue={[temperature]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: any) => setTemperature(value[0])}
                  />
                  <span className='text-xs text-gray-600'>Current Value: {temperature}</span>
                </div>

                <div className='grid gap-2'>
                  <Label htmlFor='topP'>TopP</Label>
                  <Slider
                    id='topP'
                    name='topP'
                    defaultValue={[topP]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: any) => setTopP(value[0])}
                  />
                  <span className='text-xs text-gray-600'>Current Value: {topP}</span>
                </div>

                <div className='grid gap-2'>
                  <Label htmlFor='frequencyPenalty'>Frequency Penalty</Label>
                  <Slider
                    id='frequencyPenalty'
                    name='frequencyPenalty'
                    defaultValue={[frequencyPenalty]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: any) => setFrequencyPenalty(value[0])}
                  />
                  <span className='text-xs text-gray-600'>Current Value: {frequencyPenalty}</span>
                </div>

                <div className='grid gap-2'>
                  <Label htmlFor='presencePenalty'>Presence Penalty</Label>
                  <Slider
                    id='presencePenalty'
                    name='presencePenalty'
                    defaultValue={[presencePenalty]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: any) => setPresencePenalty(value[0])}
                  />
                  <span className='text-xs text-gray-600'>Current Value: {presencePenalty}</span>
                </div>
              </div>

              <input id='name' name='name' defaultValue={name} hidden readOnly />
              <input id='shortDesc' name='shortDesc' defaultValue={shortDesc} hidden readOnly />
              <input id='greeting' name='greeting' defaultValue={greeting} hidden readOnly />
              <textarea id='description' name='description' defaultValue={description} hidden readOnly />
              <input id='published' name='published' defaultValue={avatarPublished ? 'on' : 'off'} hidden readOnly />
              <input id='embeddingModelId' name='embeddingModelId' defaultValue={embeddingModel.id} hidden readOnly />
            </div>

            <div className='grid grid-cols-2 gap-2'>
              <div className='mb-4'>
                <Button
                  size='sm'
                  className={'underline'}
                  variant='outline'
                  disabled={sendingForm}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(false);
                  }}
                >
                  <Link to={`/scenarios/${scenario.id}/edit`}>go to Full edit page</Link>
                </Button>
              </div>
              <div></div>
            </div>

            <div className='gap-3 flex items-center justify-end'>
              <Button
                size='sm'
                variant='outline'
                disabled={sendingForm}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(false);
                }}
              >
                Cancel
              </Button>

              <Separator orientation={'vertical'} className={'h-8'} />

              <Button size='sm' type='submit' disabled={sendingForm}>
                Save Changes
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default ScenarioEditMiniForm;
