import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Message } from '../types';
import { getFormattedTime } from '@/utils/formatters';

interface MessageItemProps {
  message: Message;
}

const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  const createdAt = moment(message.createdAt);

  return (
    <div className='chat-message' key={message.id}>
      <div className={`flex items-end ${message.role === 'USER' ? 'justify-end' : ''}`}>
        <div
          className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${message.role === 'USER' ? 'order-1 items-end' : 'order-2 items-start'}`}
        >
          <Link to={`/messages/${message.id}`}>
            <div>
              <span
                style={{ wordBreak: 'break-word' }}
                className={`px-4 py-2 rounded-lg inline-block ${
                  message.role === 'USER'
                    ? 'rounded-br-none bg-white border-black border-2 text-black text-sm'
                    : 'rounded-bl-none bg-gray-800 text-white text-sm'
                }`}
              >
                {message.content}
              </span>
            </div>
          </Link>
          <span className='text-gray-500 text-xs'>{getFormattedTime(createdAt)}</span>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
