import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { TtsVoice } from '@/types';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  const config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    const response = await axios.post(`${backendUrl}/tts-voices`, body, config);
    const ttsVoice: TtsVoice = response.data;

    return redirect(`/tts-voices/${ttsVoice.id}`);
  } catch (error) {
    console.error('Error creating TTS Voice:', error);
  }
};

const TtsVoiceNew: React.FC = () => {
  const { ttsProviderId } = useParams();
  const [recommended, setRecommended] = useState<boolean>(false);

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>New TTS-Voice</CardTitle>
      </CardHeader>
      <Form method='post' id='tts-voice-form'>
        <input type='text' name='ttsProviderId' value={ttsProviderId} readOnly hidden />

        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='TTS Voice Name' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='providerVoiceId'>TTS-Provider Voice</Label>
              <Input id='providerVoiceId' name='providerVoiceId' type='text' className='w-full' placeholder='TTS Voice' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Link to={`/tts-providers/${ttsProviderId}`}>
            <Button variant='secondary'>Cancel</Button>
          </Link>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default TtsVoiceNew;
