import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { ChatModel } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  if (!params.modelId) return;

  try {
    await axios.patch(`${backendUrl}/chat-models/${params.modelId}`, body, {
      headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
    });
    return redirect(`/chat-models/${params.modelId}`);
  } catch (error) {
    console.log('error', error);
  }
};

const ChatModelEdit: React.FC = () => {
  const model = useLoaderData() as ChatModel;

  const [name, setName] = useState<string>(model.name);
  const [providerModelName, setProviderModelName] = useState<string>(model.providerModelName);
  const [dollarPerInputToken, setDollarPerInputToken] = useState<number | string>(scientificNumConvert(model.dollarPerInputToken));
  const [dollarPerOutputToken, setDollarPerOutputToken] = useState<number | string>(scientificNumConvert(model.dollarPerOutputToken));
  const [contextWindow, setContextWindow] = useState<number | string>(model.contextWindow);
  const [recommended, setRecommended] = useState<boolean>(model.recommended);
  const [censored, setCensored] = useState<boolean>(model.censored);
  const [maxTokens, setMaxTokens] = useState<number | string>(model.maxTokens || '');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit Chat-Model</CardTitle>
      </CardHeader>
      <Form method='post' id='chat-model-form'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            {/* Name field */}
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Provider model name */}
            <div className='grid gap-3'>
              <Label htmlFor='providerModelName'>Provider model name</Label>
              <Input
                id='providerModelName'
                name='providerModelName'
                className='w-full'
                placeholder='Provider model name'
                required
                defaultValue={providerModelName}
                onChange={(e) => setProviderModelName(e.target.value)}
              />
            </div>

            {/* Dollar per input token */}
            <div className='grid gap-3'>
              <Label htmlFor='dollarPerInputToken'>Dollar per input token</Label>
              <Input
                id='dollarPerInputToken'
                name='dollarPerInputToken'
                className='w-full'
                placeholder='Dollar Per Input Token'
                required
                type='number'
                step='0.00000001'
                defaultValue={dollarPerInputToken}
                onChange={(e) => setDollarPerInputToken(e.target.value)}
              />
            </div>

            {/* Dollar per output token */}
            <div className='grid gap-3'>
              <Label htmlFor='dollarPerOutputToken'>Dollar per output token</Label>
              <Input
                id='dollarPerOutputToken'
                name='dollarPerOutputToken'
                className='w-full'
                placeholder='Dollar per output token'
                required
                step='0.00000001'
                type='number'
                defaultValue={dollarPerOutputToken}
                onChange={(e) => setDollarPerOutputToken(e.target.value)}
              />
            </div>

            {/* Context Window */}
            <div className='grid gap-3'>
              <Label htmlFor='contextWindow'>Context Window</Label>
              <Input
                id='contextWindow'
                name='contextWindow'
                className='w-full'
                placeholder='Context Window'
                required
                type='number'
                step='1'
                defaultValue={contextWindow}
                onChange={(e) => setContextWindow(e.target.value)}
              />
            </div>

            {/* Max Token */}
            <div className='grid gap-3'>
              <Label htmlFor='maxToken'>Max Tokens</Label>
              <Input
                id='maxTokens'
                name='maxTokens'
                className='w-full'
                placeholder='Max Tokens'
                required
                type='number'
                step='1'
                defaultValue={maxTokens}
                onChange={(e) => setMaxTokens(e.target.value)}
              />
            </div>

            {/* Recommended checkbox */}
            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='censored'>Censored</Label>
              <Checkbox id='censored' name='censored' checked={censored} onCheckedChange={() => setCensored(!censored)} />
            </div>

            <Input id='aiProviderId' name='aiProviderId' type='hidden' value={model.aiProviderId} />
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/ai-providers/${model.aiProviderId}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default ChatModelEdit;
