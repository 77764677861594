import { Form, Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { User } from '../types';
import { ethers, formatEther } from 'ethers';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { CircleArrowDown, CircleArrowOutUpRight, CircleArrowUp, Copy, Cross, Delete, KeyIcon, SquareArrowUpRight } from 'lucide-react';
export default function UserView() {
  const user = useLoaderData() as User;

  return (
    <div className='grid grid-cols-12 md:grid-cols-12 gap-4'>
      <div className='grid auto-rows-max items-start gap-4 col-span-12 md:gap-8 sm:col-span-7'>
        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>Cipherdolls Wallet</CardTitle>
            <CardDescription>
              This is your Cipherdolls Wallet Address. we use this for Payments. send Ether to this address to top up your account{' '}
            </CardDescription>
          </CardHeader>

          <CardContent>
            <div className='font-semibold'>Wallet Address</div>

            <div className='flex'>
              <Button
                title='Copy Wallet Address'
                size='icon'
                variant='outline'
                className='h-6 w-6 mr-3'
                onClick={() => {
                  navigator.clipboard.writeText(user.walletAddress);
                }}
              >
                <Copy className='h-3 w-3' />
                <span className='sr-only'>Copy Wallet Address</span>
              </Button>

              <div className='ml-1 inline-block w-[250px] md:w-[400px]'>
                <a
                  href={`https://optimistic.etherscan.io/address/${user.walletAddress}`}
                  className='underline line-clamp-1'
                  target='_blank'
                  rel={user.walletAddress}
                >
                  {user.walletAddress}
                </a>
              </div>
            </div>
            <Separator className='my-4' />

            <div className='font-semibold'>Wallet Balance</div>

            <div className='flex items-center'>
              <Form method='post' id='user-form' action='refresh'>
                <input hidden name='signerAddress' id='signerAddress' value={user.signerAddress} readOnly />
                <Button type='submit' size='icon' variant='outline' className='h-6 w-6 mr-3' title='Refresh Wallet Balance'>
                  <CircleArrowDown className='h-3 w-3' />
                </Button>
              </Form>
              <p>{formatEther(user.weiBalance)} Ether</p>
            </div>
          </CardContent>
        </Card>

        <Card className='rounded-none'>
          <CardHeader>
            <CardTitle>API Key</CardTitle>
            <CardDescription>This is your Cipherdolls API Key. use this to connect your Doll</CardDescription>
          </CardHeader>

          <CardContent>
            <Button
              title='Copy API Key'
              size='icon'
              variant='outline'
              className='h-6 w-6 mr-3'
              onClick={() => {
                navigator.clipboard.writeText(user.apikey);
              }}
            >
              <Copy className='h-3 w-3' />
              <span className='sr-only'>Copy apikey</span>
            </Button>

            {user.apikey}
          </CardContent>
        </Card>
      </div>

      <Card className='col-span-12 sm:col-span-5 h-fit flex flex-col justify-between rounded-none'>
        <CardHeader>
          <CardTitle>{user.name}</CardTitle>
          <CardDescription>This is your name used in the chats</CardDescription>
        </CardHeader>

        <CardFooter className='flex justify-between'>
          <Button className='gap-1'>
            <Link to={`/users/${user.id}/edit`}>Edit</Link>
          </Button>
          <div className='flex items-center'>
            <Button
              type='submit'
              size='icon'
              variant='outline'
              className='h-6 w-6 mr-3'
              title='Sign out'
              onClick={() => {
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              <CircleArrowOutUpRight className='h-3 w-3' />
            </Button>
            <p className='whitespace-nowrap'>Sign out</p>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
