import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';

import { backendUrl } from '..';
import { User } from '../types';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }

  let config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.userId) return;
  try {
    await axios.patch(`${backendUrl}/users/${params.userId}`, updates, config);
    return redirect(`/users/${params.userId}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function UserEdit() {
  const user = useLoaderData() as User;
  const { id, name, signerAddress } = user;

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit Account</CardTitle>
      </CardHeader>
      <Form method='post' id='user-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <input hidden name='signerAddress' id='signerAddress' value={signerAddress} readOnly />

              <Input id='name' name='name' type='text' defaultValue={name} className='w-full' placeholder='Alice' required />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link type='button' to={`/users/${id}`}>
              Cancel
            </Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
