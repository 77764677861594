import React from 'react';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';

import { PICTURE_SIZE, USER_TYPE } from '@/constants';
import { TtsVoice, User } from '@/types';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { getPicture } from '@/utils/getPicture';

const TtsVoiceList: React.FC<{ ttsVoices?: TtsVoice[] }> = ({ ttsVoices }) => {
  const data = useLoaderData() as TtsVoice[];
  const me = useRouteLoaderData('me') as User;

  const ttsVoicesList = ttsVoices || data || [];

  return (
    <>
      <div className='ml-auto flex items-center justify-between w-full pe-4'>
        <div>
          <h1 className='font-bold text-2xl'>TTS Voices</h1>
          <h3>List of available TTS Voices</h3>
        </div>
      </div>

      <Card className='overflow-x-auto rounded-none'>
        <CardContent className='p-0 md:p-0'>
          <Table>
            {me.role === USER_TYPE.admin && (
              <TableHeader>
                <TableRow>
                  <TableHead>TTS Provider</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead className='text-right'>
                    <span className='pr-3'>Action</span>
                  </TableHead>
                </TableRow>
              </TableHeader>
            )}

            <TableBody>
              {ttsVoicesList.map((voice) => (
                <TableRow key={voice.id}>
                  <TableCell className='flex items-center gap-2'>
                    <img
                      src={getPicture(voice.ttsProvider, 'tts-providers', false, PICTURE_SIZE.small)}
                      srcSet={getPicture(voice.ttsProvider, 'tts-providers', true, PICTURE_SIZE.small)}
                      className='w-6 h-6'
                    />

                    {voice.ttsProvider.name}
                  </TableCell>
                  <TableCell>{voice.name}</TableCell>
                  {me.role === USER_TYPE.admin && (
                    <TableCell className='text-right'>
                      <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                        <Link to={`/tts-voices/${voice.id}`}>View</Link>
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default TtsVoiceList;
