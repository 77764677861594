import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import { useSubmit } from 'react-router-dom';
import { StopIcon } from '@radix-ui/react-icons';
import { MicrophoneIcon } from '@heroicons/react/20/solid';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import Tooltip from '@/components/ui/tooltip';

type MessageAudioNewProps = {
  chatId: string;
  onRecordingStart: () => void;
};

const isMobileDevice = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);

const MessageAudioNew = ({ chatId, onRecordingStart }: MessageAudioNewProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const isRecordingRef = useRef(isRecording);
  const isCancelledRef = useRef(isCancelled);
  const isMountedRef = useRef(true);
  const submit = useSubmit();

  const { startRecording, stopRecording, clearBlobUrl, unMuteAudio } = useReactMediaRecorder({
    audio: true,
    onStop: (blobUrl, blob) => {
      if (isCancelledRef.current) {
        clearBlobUrl();
      } else {
        const file = new File([blob], 'audio.webm', { type: 'audio/webm' });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatId', chatId);

        if (isMountedRef.current) {
          submit(formData, { method: 'post', encType: 'multipart/form-data' });
          clearBlobUrl();
        }
      }
    },
  });

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setPermissionGranted(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setPermissionGranted(false);
    }
  };

  useEffect(() => {
    if (!permissionGranted) {
      requestMicrophoneAccess();
    }
  }, [permissionGranted]);

  const handleStartRecording = () => {
    setIsCancelled(false);
    isCancelledRef.current = false;
    setIsRecording(true);
    isRecordingRef.current = true;

    setTimeout(() => {
      startRecording();
      onRecordingStart();
      unMuteAudio();
    }, 10);
  };

  const handleStopRecording = () => {
    setIsCancelled(false);
    setIsRecording(false);
    isCancelledRef.current = false;
    isRecordingRef.current = false;
    setTimeout(() => stopRecording(), 100);
  };

  const handleCancelRecording = () => {
    setIsCancelled(true);
    setIsRecording(false);
    isCancelledRef.current = true;
    isRecordingRef.current = false;
    stopRecording();
    clearBlobUrl();
  };

  const handleMobileClick = () => {
    if (isRecording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  };

  const handleTouchStart = () => {
    handleStartRecording();
  };

  const handleTouchEnd = () => {
    handleStopRecording();
  };

  const handleMouseDown = () => {
    handleStartRecording();
  };

  const handleMouseUp = () => {
    if (isRecordingRef.current) {
      handleStopRecording();
    }
  };

  const handleMouseLeave = () => {
    if (isRecordingRef.current) {
      handleCancelRecording();
    }
  };

  return (
    <div className='relative'>
      <Tooltip content={isMobileDevice() ? 'Tap to start/stop recording' : 'Hold to start recording'}>
        <motion.button
          onTouchStart={isMobileDevice() ? handleTouchStart : undefined}
          onTouchEnd={isMobileDevice() ? handleTouchEnd : undefined}
          onMouseDown={!isMobileDevice() ? handleMouseDown : undefined}
          onMouseUp={!isMobileDevice() ? handleMouseUp : undefined}
          onMouseLeave={!isMobileDevice() ? handleMouseLeave : undefined}
          onClick={isMobileDevice() ? handleMobileClick : undefined}
          className={classNames(
              'p-3 rounded-full text-white focus:outline-none active:bg-red-600 hover:bg-black',
              isRecording ? 'bg-red-600' : 'bg-gray-500/80',
              !isMobileDevice() && isRecording ? 'bg-red-600' : 'bg-gray-500/80'
          )}
          whileTap={{scale: 1.1}}
          initial={{scale: 1}}
          animate={isRecording ? {scale: 1.2, rotate: 360} : {scale: 1, rotate: 0}}
          transition={{type: 'spring', stiffness: 400, damping: 10}}
          disabled={!permissionGranted}
        >
          {isRecording ? <StopIcon className='h-5 w-5'/> : <MicrophoneIcon className='h-5 w-5'/>}
        </motion.button>
      </Tooltip>
    </div>
  );
};

export default MessageAudioNew;
