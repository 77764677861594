import { Link } from 'react-router-dom';

import { Doll } from '../types';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import {getPicture} from "@/utils/getPicture";
import React from "react";

const Dolls = ({ dolls }: { dolls: Doll[] }) => {
  return (
    <Card className='rounded-none'>
      <CardHeader className='bg-muted/50 '>
        <CardTitle>Dolls</CardTitle>
        <CardDescription>Showing all your Dolls.</CardDescription>
      </CardHeader>
      <CardContent className={'p-0 md:p-0'}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Picture</TableHead>
              <TableHead>MacAddress</TableHead>
              <TableHead className='text-right'></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {dolls.map((doll) => (
              <TableRow key={doll.id}>
                <TableCell>
                  {doll.picture ? <img
                    className='h-8 w-8 rounded-full'
                    src={getPicture(doll, 'dolls', false)}
                    srcSet={getPicture(doll, 'dolls', true)}
                    alt='Doll Picture'
                  /> : <span>No Picture</span>}
                </TableCell>
                <TableCell>{doll.macAddress}</TableCell>

                <TableCell className='text-right flex gap-2 justify-end'>
                  <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                    <Link to={`/dolls/${doll.id}`}>View</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Dolls;
