import { FC } from 'react';
import { Toast, ToastTitle, ToastDescription, ToastClose } from '@radix-ui/react-toast';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

type JobEventToastProps = {
  description: string;
  icon: JSX.Element;
  id?: string;
  jobStatus: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const JobEventToast: FC<JobEventToastProps> = ({ description, icon, id, jobStatus, isOpen, setIsOpen }) => {
  const content = (
    <div className='flex items-center gap-2 text-sm'>
      <ToastTitle className='font-bold'>{icon}</ToastTitle>
      <ToastDescription>{description}</ToastDescription>
    </div>
  );

  const backgroundColor = jobStatus === 'failed' ? 'bg-red-500 text-white' : 'bg-white';

  return (
    <Toast
      className={`flex items-center justify-between gap-4 ${backgroundColor} border border-gray-200 text-gray-900 p-2 rounded-lg shadow-lg mt-2`}
      duration={Infinity}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {id ? <Link to={`/chat-completion-jobs/${id}`}>{content}</Link> : content}
      <ToastClose className='bg-transparent p-1 text-sm text-gray-900 hover:bg-gray-200 max-h-8 rounded'>
        <Cross2Icon className='text-gray-900' color={jobStatus === 'failed' ? 'white' : 'black'} />
      </ToastClose>
    </Toast>
  );
};

export default JobEventToast;
