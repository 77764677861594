import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { ITtsVoiceEdit } from '@/types';
import { setLastPath } from '@/utils/common';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  const config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  if (!params.ttsVoiceId) return;

  try {
    await axios.patch(`${backendUrl}/tts-voices/${params.ttsVoiceId}`, body, config);
    return redirect(`/tts-voices/${params.ttsVoiceId}`);
  } catch (error) {
    console.log('error', error);
  }
};

const TtsVoiceEdit: React.FC = () => {
  const data = useLoaderData() as ITtsVoiceEdit;
  const { ttsVoice } = data;

  const [name, setName] = useState(ttsVoice.name);
  const [recommended, setRecommended] = useState<boolean>(ttsVoice.recommended);
  const [providerVoiceId, setProviderVoiceId] = useState(ttsVoice.providerVoiceId || '');
  const [ttsProviderId, setTtsProviderId] = useState(ttsVoice.ttsProviderId || '');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit TTS-Voice</CardTitle>
      </CardHeader>
      <Form method='post' id='tts-voice-form'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='providerVoiceId'>Provider Voice ID</Label>
              <Input
                id='providerVoiceId'
                name='providerVoiceId'
                type='text'
                className='w-full'
                placeholder='Enter Provider Voice ID'
                required
                value={providerVoiceId}
                onChange={(e) => setProviderVoiceId(e.target.value)}
              />
            </div>

            {ttsVoice.ttsProviderId ? (
              <div className='grid gap-3'>
                <Label htmlFor='ttsProviderId'>TTS Provider ID</Label>
                <Input
                  id='ttsProviderId'
                  name='ttsProviderId'
                  type='text'
                  className='w-full'
                  placeholder='Enter TTS Provider ID'
                  required
                  value={ttsProviderId}
                  onChange={(e) => setTtsProviderId(e.target.value)}
                />
              </div>
            ) : null}

            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/tts-voices/${ttsVoice.id}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default TtsVoiceEdit;
