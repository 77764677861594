import React, { useState } from 'react';
import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { IEmbeddingModelEdit } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { setLastPath } from '@/utils/common';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  if (!params.modelId) return;

  try {
    await axios.patch(`${backendUrl}/embedding-models/${params.modelId}`, body, config);
    return redirect(`/embedding-models/${params.modelId}`);
  } catch (error) {
    console.log('error', error);
  }
};

const EmbeddingModelEdit: React.FC = () => {
  const data = useLoaderData() as IEmbeddingModelEdit;
  const { aiProviders, embedModel } = data;

  const [name, setName] = useState<string>(embedModel.name);
  const [providerModelName, setProviderModelName] = useState<string>(embedModel.providerModelName);
  const [dollarPerInputToken, setDollarPerInputToken] = useState<number | string>(scientificNumConvert(embedModel.dollarPerInputToken));
  const [dollarPerOutputToken, setDollarPerOutputToken] = useState<number | string>(scientificNumConvert(embedModel.dollarPerOutputToken));
  const [recommended, setRecommended] = useState<boolean>(embedModel.recommended);

  const getAiProviderId = () => {
    let res = '';

    aiProviders.some((provider) => {
      provider.embeddingModels.some((providerEmbedModel) => {
        if (providerEmbedModel.id === embedModel.id) {
          res = provider.id;
        }
        return res !== '';
      });
      return res !== '';
    });
    return res;
  };

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Edit Embedding-Model</CardTitle>
      </CardHeader>
      <Form method='post' id='embedding-model-form'>
        <CardContent>
          <Input id='aiProviderId' name='aiProviderId' type={'hidden'} value={getAiProviderId()} />

          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='providerModelName'>Provider model name</Label>
              <Input
                id='providerModelName'
                name='providerModelName'
                className='w-full'
                placeholder='Provider model name'
                required
                defaultValue={providerModelName}
                onChange={(e) => setProviderModelName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerInputToken'>Dollar per input token</Label>
              <Input
                id='dollarPerInputToken'
                name='dollarPerInputToken'
                className='w-full'
                placeholder='Dollar Per Input Token'
                required
                type={'number'}
                step='0.00000001'
                defaultValue={dollarPerInputToken}
                onChange={(e) => setDollarPerInputToken(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerOutputToken'>Dollar per output token</Label>
              <Input
                id='dollarPerOutputToken'
                name='dollarPerOutputToken'
                className='w-full'
                placeholder='Dollar per output token'
                required
                step='0.00000000001'
                type={'number'}
                defaultValue={dollarPerOutputToken}
                onChange={(e) => setDollarPerOutputToken(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='recommended'>Recommended</Label>
              <Checkbox id='recommended' name='recommended' checked={recommended} onCheckedChange={() => setRecommended(!recommended)} />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/embedding-models/${embedModel.id}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default EmbeddingModelEdit;
