import { useLoaderData } from 'react-router-dom';

import ChatModelsList from '@/chatModel/ChatModelList';
import SttProviderList from '@/sttProvider/SttProviderList';
import TtsVoiceList from '@/ttsVoice/TtsVoiceList';
import { ChatModel, SttProvider, TtsVoice } from '@/types';

export const Services = () => {
  const data = useLoaderData() as {
    ttsVoices: TtsVoice[];
    sttProviders: SttProvider[];
    chatModels: ChatModel[];
  };

  return (
    <div className='flex flex-col gap-4 overflow-x-auto rounded-none'>
      <SttProviderList sttProviders={data?.sttProviders} />
      <TtsVoiceList ttsVoices={data.ttsVoices} />
      <ChatModelsList chatModels={data?.chatModels} forServices={true}/>
    </div>
  );
};
