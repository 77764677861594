import * as TooltipPrimitive from '@radix-ui/react-tooltip';

type TooltipProps = {
  content: string;
  side?: 'top' | 'bottom' | 'left' | 'right';
} & React.PropsWithChildren;

const Tooltip = ({ content, children, side = 'top' }: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider delayDuration={200}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={side}
            sideOffset={5}
            className='bg-black dark:bg-white dark:text-black text-white text-xs px-2 py-1 rounded-md shadow-lg'
          >
            {content}
            <TooltipPrimitive.Arrow className='fill-black' />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
