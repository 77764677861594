import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { ChatEmbeddingJob } from '@/types';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { getPicture } from '@/utils/getPicture';
import { PICTURE_SIZE } from '@/constants';
import { etherWeiConverter } from '@/utils/etherWeiConverter';
import { Puzzle } from 'lucide-react';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';

const ChatEmbeddingJobView: React.FC<{ embeddingJobData?: ChatEmbeddingJob }> = ({ embeddingJobData }) => {
  let job = useLoaderData() as ChatEmbeddingJob;

  if (embeddingJobData) {
    job = embeddingJobData;
  }

  const goBack = () => {
    window.history.back();
  };

  if (!job || !job.id) return null;

  const embeddingModel = job?.message?.chat?.scenario?.embeddingModel;

  return (
    <Card className='rounded-none'>
      <CardHeader className={'border-b'}>
        <CardTitle>
          <Puzzle /> Embedding Job
        </CardTitle>
      </CardHeader>

      <CardContent className={'bg-muted/50'}>
        <div className={'mb-2'}>
          <div className='grid grid-cols-3 w-full gap-0.5'>
            <div className='col-span-2'>
              <p>
                <strong>AI Provider:</strong> {embeddingModel?.aiProvider?.name}
              </p>
              <p>
                <strong>Embedding Model:</strong> {embeddingModel?.name}
              </p>
            </div>
            <div className='text-sm ml-auto'>
              {' '}
              <img
                src={getPicture(embeddingModel?.aiProvider, 'ai-providers', false, PICTURE_SIZE.small)}
                srcSet={getPicture(embeddingModel?.aiProvider, 'ai-providers', true, PICTURE_SIZE.small)}
                alt=''
              />
            </div>
          </div>
        </div>

        {/* Token and Cost Details */}
        <div>
          <p>
            <strong>Cost (USD):</strong> ${scientificNumConvert(job.usdCost)}
          </p>
          <p>
            <strong>Time Taken:</strong> {job.timeTakenMs} ms
          </p>
        </div>

        <Separator className='my-4' />

        {/* Payment Job */}
        <div>
          <h4 className='mb-4'>Payment Job</h4>

          {job.paymentJob ? (
            <div className='grid gap-0.5'>
              <p>
                <strong>Cost: </strong>
                {etherWeiConverter(job.paymentJob.weiCost)} Ether
              </p>

              <p className={'flex gap-2 truncate break-normal'}>
                <strong>txHash:</strong>
                <a
                  href={`https://optimistic.etherscan.io/tx/${job.paymentJob.txHash}`}
                  className='underline line-clamp-1 block truncate break-normal'
                  target='_blank'
                  rel={job.paymentJob.txHash}
                >
                  {job.paymentJob.txHash}
                </a>
              </p>
            </div>
          ) : (
            <div className='grid justify-center gap-0.5 min-w-[64px] mb-4'>
              <p>Free provider call. No costs incurred</p>
            </div>
          )}
        </div>
      </CardContent>

      {!embeddingJobData && (
        <CardFooter>
          <Button variant='outline' onClick={goBack} className='max-w-[100px]'>
            Go Back
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default ChatEmbeddingJobView;
