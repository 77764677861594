import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { SttProvider, User } from '@/types';
import {PICTURE_SIZE, USER_TYPE} from '@/constants';
import { scientificNumConvert } from '@/utils/scientificNumberConvertation';
import { getPicture } from "@/utils/getPicture";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

const SttProviderList: React.FC<{ sttProviders?: SttProvider[] }> = ({ sttProviders }) => {
  const me = useRouteLoaderData('me') as User;
  const data = useLoaderData() as SttProvider[];
  const list = sttProviders || data || [];

  return (
    <>
      <div className='ml-auto flex items-center justify-between w-full pe-4'>
        <div>
          <h1 className={'font-bold text-2xl'}>STT-Providers</h1>
          <h3>Overview</h3>
        </div>
        {me.role === USER_TYPE.admin && (
          <Button size='sm' className='h-8 gap-2 pe-6 rounded-2xl font-semibold'>
            <Plus className='h-3.5 w-3.5' />
            <Link to='/stt-providers/new'>STT-Provider</Link>
          </Button>
        )}
      </div>

      <Card x-chunk='dashboard-06-chunk-0' className='overflow-x-auto rounded-none'>
        <CardContent className='p-0 md:p-0'>
          <Table>
            {me.role === USER_TYPE.admin && (
              <TableHeader>
                <TableRow>
                  <TableHead>Picture</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Dollar per second</TableHead>
                  <TableHead>Recommended</TableHead>
                  {me.role === USER_TYPE.admin && <TableHead></TableHead>}
                </TableRow>
              </TableHeader>
            )}

            <TableBody>
              {list?.map((provider) => (
                <TableRow key={provider.id}>
                  <TableCell>
                    <img
                      src={getPicture(provider, 'stt-providers', false, PICTURE_SIZE.smallest)}
                      srcSet={getPicture(provider, 'stt-providers', true, PICTURE_SIZE.smallest)}
                      alt=''
                    />
                  </TableCell>
                  <TableCell>{provider.name}</TableCell>
                  <TableCell>${scientificNumConvert(provider.dollarPerSecond)}</TableCell>
                  <TableCell>{provider.recommended.toString()}</TableCell>
                  {me.role === USER_TYPE.admin && (
                    <TableCell className='text-right'>
                      <Button size='sm' className='h-8 gap-1 rounded-2xl font-semibold'>
                        <Link to={`/stt-providers/${provider.id}`}>View</Link>
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default SttProviderList;
