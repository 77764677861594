import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ActionFunction, Form, Link, redirect } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '@/index';
import { setLastPath } from '@/utils/common';

import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    setLastPath();
    return redirect('/signin');
  }
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    await axios.post(`${backendUrl}/tts-providers`, body, config);
    return redirect(`/tts-providers`);
  } catch (error) {
    console.log('error', error);
  }
};

const TtsProviderNew: React.FC = () => {
  const [name, setName] = useState('');
  const [hostname, setHostname] = useState('');
  const [dollarPerChar, setDollarPerChar] = useState<string | number>(0);
  const [image, setImage] = useState<FileList | any>('');

  return (
    <Card className='rounded-none'>
      <CardHeader>
        <CardTitle>Create TTS-Provider</CardTitle>
      </CardHeader>
      <Form method='post' id='tts-provider-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-4 md:gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                name='name'
                type='text'
                className='w-full'
                placeholder='Name'
                required
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='apiKey'>API Key</Label>
              <Input id='apiKey' name='apiKey' placeholder={'API Key'} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='dollarPerCharacter'>Dollar per character</Label>
              <Input
                id='dollarPerCharacter'
                name='dollarPerCharacter'
                className='w-full'
                placeholder='Dollar per char'
                required
                step='0.00000001'
                type={'number'}
                defaultValue={dollarPerChar}
                onChange={(e) => setDollarPerChar(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='hostname'>Hostname</Label>
              <Input
                id='hostname'
                name='hostname'
                className='w-full'
                placeholder='Hostname'
                required
                type={'text'}
                defaultValue={hostname}
                onChange={(e) => setHostname(e.target.value)}
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='file-button'></Label>

              <div className={'mb-4'}>
                {image && typeof image !== 'string' ? (
                  <img alt='tts-provider-picture' className={'h-10 w-10'} src={URL.createObjectURL(image[0])} />
                ) : (
                  <img alt='tts-provider-picture' className={'h-10 w-10'} src={`https://placehold.co/65x64?text=No+Picture`} />
                )}
              </div>
              <Input id='file-button' name='picture' type='file' onChange={(e) => setImage(e.target.files)} />
            </div>
          </div>
        </CardContent>

        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button onClick={(e) => e.preventDefault()}>
            <Link to={`/tts-providers`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default TtsProviderNew;
